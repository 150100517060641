
export const __MODULE_NAME__= "debugHelper"
const enableHttpDebug = true
const BLUE='\u001b[1;36m'
const ORANGE='\u001b[38;5;173m'
const YELLOW='\u001b[1;33m'
const RED='\u001b[1;31m'
const CLEAR='\u001b[0m'

const syntaxHighlight = (json) => {
    if (typeof json != 'string') {
         json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = BLUE; // Key
            } else {
                cls = ORANGE; // Text
            }
        } else if (/true|false/.test(match)) {
            cls = YELLOW; // Boolean
        } else if (/null/.test(match)) {
            cls = RED; // NULL
        }
        return cls + match + CLEAR;
    });
}

const jsonPrettify = obj => syntaxHighlight(JSON.stringify(obj, null, 2))

export const debugHttp = (url, res) => {
  if (enableHttpDebug && res?.request?.responseHeaders)
  console.log(`
******************** Request [${res?.config?.method}] URL: ${url} ************************
Request Headers: ${jsonPrettify(res?.config?.headers)}
Response Headers: ${jsonPrettify(res?.request?.responseHeaders)}
*****************************************************************`)
}

