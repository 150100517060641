import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userReducer from "./reducers/user.reducer";
import appReducer from "./reducers/app.reducer";
import stockReducer from "./reducers/stock.reducer";
import commandeReducer from "./reducers/commande.reducer";
import boutiqueReducer from "./reducers/boutique.reducer";
import fournisseurReducer from "./reducers/fournisseur.reducer";
import dcompanyReducer from "./reducers/delieverycompany.reducer";
import sponsorReducer from "./reducers/sponsoring.reducer";
import colisReducer from "./reducers/colis.reducer";
import paiementReducer from "./reducers/paiement.reducer";
import chargesReducer from "./reducers/achat.reducer";
import attributeReducer from "./reducers/attribute.reducer";
import clientextraReducer from "./reducers/clientextra.reducer";
import clientExtraCommandeReducer from "./reducers/extraclientcommande.reducer";
import udataReducer from "./reducers/udata.reducer";
import chartContentReducer from "./reducers/chart.reducer";
import dashboardReducer from "./reducers/dashboard.reducer";

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: {
    app: appReducer,
    stock: stockReducer,
    commande: commandeReducer,
    coli: colisReducer,
    udata: udataReducer,
    boutique: boutiqueReducer,
    fournisseur: fournisseurReducer,
    dcompany: dcompanyReducer,
    user: persistedReducer,
    sponsors: sponsorReducer,
    paiement: paiementReducer,
    charges: chargesReducer,
    attributes: attributeReducer,
    client: clientextraReducer,
    clientXCommandes: clientExtraCommandeReducer,
    chartContent: chartContentReducer,
    dashobard: dashboardReducer,
  },

  devTools:{
    trace: true,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);