import {
  AllCommandeIcon,
  CancelledIcon,
  ConfirmedIcon,
  InProgressShipIcon,
  NoResponseIcon,
  PackedIcon,
  PendingIcon,
  ReadyIcon,
  UnavailableConfirmed,
} from "./icons";

export function mapBoutiquesToOptions(boutiques) {
  if (!boutiques) return [];

  return boutiques.map((boutique) => ({
    label: boutique?.storeName,
    value: boutique?.storeId,
  }));
}

export function mapBoutiquesToSidebarFilterOptions(boutiques) {
  if (!boutiques) return [];

  return boutiques.map((boutique) => ({
    label: (
      <div style={{ width: "100%" }}>
        {boutique?.storeName} <span className="brand-count"></span>
      </div>
    ),
    value: boutique?.storeId,
  }));
}

export function mapFournisseursToOptions(fournisseurs) {
  if (!fournisseurs) return [];
  return fournisseurs
    .filter((fournisseur) => fournisseur.deleted === false) // Filter companies with status "Active"
    .map((fournisseur) => ({
      label: fournisseur?.name,
      value: fournisseur?.id,
    }));
}

export function mapFournisseursPaymentsToOptions(fournisseurs) {
  if (!fournisseurs) return [];
  return fournisseurs
    .filter(
      (fournisseur) =>
        fournisseur.deleted === false && fournisseur.notPayedAmount > 0
    )
    .map((fournisseur) => ({
      label: `${fournisseur?.name} - ${Number(
        fournisseur?.notPayedAmount
      )?.toFixed(3)} TND`,
      value: fournisseur?.id,
      notpay: fournisseur?.notPayedAmount,
    }));
}

export function mapClientsPaymentsToOptions(clients) {
  if (!clients) return [];
  return clients
    .filter((client) => client.status === "Active") // Filter companies with status "Active"
    .map((client) => ({
      label: `${client?.name} - ${Number(client?.notPayedAmount)?.toFixed(
        3
      )} TND`,
      value: client?.id,
      notpay: client?.notPayedAmount,
    }));
}

export function mapCategoryToOptions(categories) {
  if (!categories) return [];

  return categories.map((cat) => ({
    label: cat?.categoryName,
    value: cat?.id,
  }));
}

export function mapDelieveredCompaniesPaiementsToOptions(companies) {
  if (!companies) return [];

  return companies
    .filter((company) => company.status === "Active") // Filter companies with status "Active"
    .map((company) => ({
      label: `${company?.name} - ${
        company?.NotPayedAmount
          ? Number(company?.NotPayedAmount).toFixed(3)
          : (0).toFixed(3)
      } TND`,
      value: company?.id,
      notpay: company?.NotPayedAmount ? company?.NotPayedAmount : 0,
    }));
}

export function mapDelieveredCompaniesToOptions(companies) {
  if (!companies) return [];

  return companies
    .filter((company) => company.status === "Active") // Filter companies with status "Active"
    .map((company) => ({
      label: company?.name,
      value: company?.id,
      frais: company?.deliveryFee,
    }));
}

export function mapExtraClientToOptions(clients) {
  if (!clients) return [];

  return clients
    .filter((client) => client.status === "Active")
    .map((client) => ({
      label: client?.name,
      value: client?.id,
    }));
}

export function mapSponsoringsToOptions(sponsoring) {
  if (!sponsoring) return [];

  return sponsoring.map((sponsor) => ({
    label: sponsor?.companyCode,
    value: sponsor?.id,
  }));
}

export function hasPermission(mine_permissions, name, permission) {
  const item = mine_permissions?.find((item) => item?.name === name);
  if (item) {
    return item?.permissions?.includes(permission);
  }
  return false;
}

export const colisStatusOptions = [
  { value: "Colis en cours", label: "En Cours" },
  { value: "Livré", label: "Livré" },
  { value: "LivréPayé", label: "Payé" },
  { value: "Retour", label: "Retour" },
  { value: "RetourPayé", label: "Retour Payé" },
  { value: "RetourNonRéçu", label: "Retour Non Réçu" },
  // { value: "Colis retard", label: "En Retard" },
  { value: "Echange", label: "Echange" },
];

export const statusOptions = [
  { value: "En attente", label: "En attente" },
  { value: "Confirmé", label: "Confirmé" },
  { value: "Emballé", label: "Emballé" },
  { value: "Prêt", label: "Prêt" },
  // { value: "En cours", label: "En cours" },
  {
    value: "Confirmé/Indisponible",
    label: "Confirmé/Indisponible",
  },
  { value: "Aucune réponse", label: "Aucune réponse" },
  { value: "Annulé", label: "Annulé" },
  { value: "Coli en cours", label: "En Cours" },
];

export const regions = [
  { value: "Ariana", label: "Ariana" },
  { value: "Beja", label: "Beja" },
  { value: "Ben Arous", label: "Ben Arous" },
  { value: "Bizerte", label: "Bizerte" },
  { value: "Gabes", label: "Gabes" },
  { value: "Gafsa", label: "Gafsa" },
  { value: "Jendouba", label: "Jendouba" },
  { value: "Kairouan", label: "Kairouan" },
  { value: "Kasserine", label: "Kasserine" },
  { value: "Kebili", label: "Kebili" },
  { value: "Kef", label: "Kef" },
  { value: "Mahdia", label: "Mahdia" },
  { value: "Manouba", label: "Manouba" },
  { value: "Medenine", label: "Medenine" },
  { value: "Monastir", label: "Monastir" },
  { value: "Nabeul", label: "Nabeul" },
  { value: "Sfax", label: "Sfax" },
  { value: "Sidi Bouzid", label: "Sidi Bouzid" },
  { value: "Siliana", label: "Siliana" },
  { value: "Sousse", label: "Sousse" },
  { value: "Tataouine", label: "Tataouine" },
  { value: "Tozeur", label: "Tozeur" },
  { value: "Tunis", label: "Tunis" },
  { value: "Zaghouan", label: "Zaghouan" },
];

export const status = [
  "En attente",
  "Confirmé",
  "Emballé",
  "Prêt",
  "En cours",
  "Confirmé/Indisponible",
  "Aucune réponse",
  "Annulé",
];

export const commandeStatusWIcon = [
  {
    text: "Tous",
    icon: <AllCommandeIcon />,
    barIcon: <AllCommandeIcon size="33" position={true} />,
  },
  {
    text: "En attente",
    icon: <PendingIcon />,
    barIcon: <PendingIcon size="34" position={true} />,
  },
  {
    text: "Confirmé",
    icon: <ConfirmedIcon />,
    barIcon: <ConfirmedIcon size="20" position={true} />,
  },
  {
    text: "Emballé",
    icon: <PackedIcon />,
    barIcon: <PackedIcon size="40" position={true} />,
  },
  {
    text: "Prêt",
    icon: <ReadyIcon />,
    barIcon: <ReadyIcon size="32" position={true} />,
  },
  // {
  //   text: "En cours",
  //   icon: <InProgressShipIcon />,
  //   barIcon: <InProgressShipIcon size="33" position={true} />,
  // },
  {
    text: "Confirmé/Indisponible",
    icon: <UnavailableConfirmed />,
    barIcon: <UnavailableConfirmed size="33" position={true} />,
  },
  {
    text: "Aucune réponse",
    icon: <NoResponseIcon />,
    barIcon: <NoResponseIcon size="35" position={true} />,
  },
  {
    text: "Annulé",
    icon: <CancelledIcon />,
    barIcon: <CancelledIcon size="30" position={true} />,
  },
];

export const colisStatusWIcon = [
  {
    text: "Tous",
    xVal: "Tous",
    icon: <AllCommandeIcon />,
    barIcon: <AllCommandeIcon size="33" position={true} />,
  },
  {
    text: "En Cours",
    xVal: "Coli en cours",
    icon: <PendingIcon />,
    barIcon: <PendingIcon size="34" position={true} />,
  },
  {
    text: "Livré",
    xVal: "Livré",
    icon: <ConfirmedIcon />,
    barIcon: <ConfirmedIcon size="20" position={true} />,
  },
  {
    text: "Payé",
    xVal: "LivréPayé",
    icon: <PackedIcon />,
    barIcon: <PackedIcon size="40" position={true} />,
  },
  {
    text: "Retour",
    xVal: "Retour",
    icon: <ReadyIcon />,
    barIcon: <ReadyIcon size="32" position={true} />,
  },
  {
    text: "Retour Payé",
    xVal: "RetourPayé",
    icon: <InProgressShipIcon />,
    barIcon: <InProgressShipIcon size="33" position={true} />,
  },
  {
    text: "Retour Non Réçu",
    xVal: "RetourNonRéçu",
    icon: <UnavailableConfirmed />,
    barIcon: <UnavailableConfirmed size="33" position={true} />,
  },
  {
    text: "Echange",
    xVal: "Echange",
    icon: <NoResponseIcon />,
    barIcon: <NoResponseIcon size="35" position={true} />,
  },
];

export const CommandebgStatus = {
  "en attente": {
    bg: "#FA8B0C10",
    color: "#FA8B0C",
  },
  confirmé: {
    bg: "#20C99710",
    color: "#20C997",
  },
  emballé: {
    bg: "#5F63F210",
    color: "#5F63F2",
  },
  indisponible: {
    bg: "#FF69A510",
    color: "#FF69A5",
  },
  "aucune réponse": {
    bg: "#FF4D4F10",
    color: "#FF4D4F",
  },
  prêt: {
    bg: "#0cfaac10",
    color: "#0cfaac",
  },
  annulé: {
    bg: "#d8010110",
    color: "#d80101",
  },
};

export const ColisbgStatus = {
  "coli en cours": {
    bg: "#FA8B0C10",
    color: "#FA8B0C",
  },
  livré: {
    bg: "#20C99710",
    color: "#20C997",
  },
  echange: {
    bg: "#5F63F210",
    color: "#5F63F2",
  },
  retourpayé: {
    bg: "#FF69A510",
    color: "#FF69A5",
  },
  retour: {
    bg: "#FF4D4F10",
    color: "#FF4D4F",
  },
  livrépayé: {
    bg: "#0cfaac10",
    color: "#0cfaac",
  },
  retournonréçu: {
    bg: "#d8010110",
    color: "#d80101",
  },
};

export const colors = {
  "En attente": {
    color: "rgb(157 157 157)",
    background: "#eaeaea",
  },
  annulé: {
    color: "rgb(239, 71, 112)",
    background: "rgb(254, 241, 244)",
  },
  Confirmé: {
    color: "#5b841b",
    background: "#c6e1c6",
  },
  Emballé: {
    color: "rgb(132 27 132)",
    background: "rgb(217 198 225)",
  },
  "En cours": {
    color: "rgb(200 94 30)",
    background: "rgb(255 227 193)",
  },
  "Confirmé/Indisponible": {
    color: "rgb(182 14 171)",
    background: "rgb(255 215 249)",
  },
  Prêt: {
    color: "rgb(26 101 3)",
    background: "rgb(197 255 169)",
  },
  "Aucune réponse": {
    color: "rgb(186, 70, 70)",
    background: "rgb(255 183 183)",
  },
  Annulé: {
    color: "rgb(255 255 255)",
    background: "rgb(255 87 87)",
  },
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
