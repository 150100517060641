export const COMMANDE_PERM_NAME = "Commande client";
export const COLIS_PERM_NAME = "Colis";
export const STOCK_PERM_NAME = "Stock";
export const DASHBOARD_PERM_NAME = "Dashboard";
export const DELIVERY_PERM_NAME = "Société de livraison";
export const CLIENT_PERM_NAME = "Clients Supplémentaires";
export const STATISTIC_PERM_NAME = "Statistique et gain";
export const BOUTIQUE_PERM_NAME = "Vos boutiques";
export const BONSORTIE_PERM_NAME = "Bon des sorties";
export const FOURNISSEUR_PERM_NAME = "Fournisseur";
export const EQUIPE_PERM_NAME = "Gestion d'equipe"
export const OFFRE_PERM_NAME = "Offers";
export const CHARGE_PERM_NAME = "Charge";
export const SPONSORING_PERM_NAME = "Sponsoring";
export const PAIEMENT_RECU_PERM_NAME = "Paiement reçus";
export const PAIEMENT_EFFECTED_PER_NAME = "Paiement effectués";
export const CLIENT_SUPPLIMENTAIRE_LIST_PER_NAME = "Clients Supp";
export const CLIENT_SUPPLIMENTAIRE_COMMANDE_PER_NAME = "Commandes Clients Supp";
export const CREATE_PERM = "CREATE";
export const ARRIVAGE_CREATE_PERM = "ARRIVAGE_CREATE";
export const ARRIVAGE_FETCH_PERM = "ARRIVAGE_FETCH";
export const FETCH_PERM = "FETCH";
export const DELETE_PERM = "DELETE";
export const EDIT_PERM = "EDIT";
