import {
  GET_INCOME_BEGIN,
  GET_INCOME_ERR,
  GET_INCOME_SUCCESS,
  GET_STATISTICS_GAINS_SPINNER_DIS,
  GET_STATISTICS_GAINS_SPINNER_EN,
  GET_STATISTICS_GAINS_SUCCESS,
  GET_STATISTICS_PROFIT_SPINNER_DIS,
  GET_STATISTICS_PROFIT_SPINNER_EN,
  GET_STATISTICS_PROFIT_SUCCESS,
} from "../types";

const initialState = {
  incomeData: null,
  icLoading: false,
  statistics: null,
  statistics_loading: false,
  profitData: [],
  profitLoading: false,
};

export default function chartContentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STATISTICS_PROFIT_SPINNER_EN:
      return {
        ...state,
        profitLoading: true,
      };
    case GET_STATISTICS_PROFIT_SPINNER_DIS:
      return {
        ...state,
        profitLoading: false,
      };
    case GET_STATISTICS_PROFIT_SUCCESS:
      return {
        ...state,
        profitData: action.payload,
      };
    case GET_STATISTICS_GAINS_SPINNER_EN:
      return {
        ...state,
        statistics_loading: true,
      };
    case GET_STATISTICS_GAINS_SPINNER_DIS:
      return {
        ...state,
        statistics_loading: false,
      };
    case GET_STATISTICS_GAINS_SUCCESS:
      return {
        ...state,
        statistics: action.payload,
      };
    case GET_INCOME_BEGIN:
      return {
        ...state,
        icLoading: true,
      };
    case GET_INCOME_SUCCESS:
      return {
        ...state,
        incomeData: action.payload,
        icLoading: false,
      };
    case GET_INCOME_ERR:
      return {
        ...state,
        error: action.payload,
        icLoading: false,
      };
    default:
      return state;
  }
}
