import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import AuthentificatedNavbar from "../components/Navbar/AuthentificatedNavbar";
import useWindowSize from "../hooks/useWindowSize";
const ThemeLayout = (WrappedComponent) => {
  const LayoutComponent = (props) => {
    const { width } = useWindowSize();
    const [collapsedSidebar, setCollapsedSidebar] = useState(false);
    const toggleCollapseSidebar = () => {
      setCollapsedSidebar(!collapsedSidebar);
    };
    const [isOpen, setisOpen] = useState(false);
    const closeSidebar = () => {
      setisOpen(false);
    };
    const openSidebar = () => {
      setisOpen(!isOpen);
    };

    useEffect(() => {
      if (width > 850) {
        setCollapsedSidebar(false);
      } else {
        setCollapsedSidebar(true);
      }
    }, [width]);

    return (
      <div className="orty-layout">
        <AuthentificatedNavbar
          openSidebar={openSidebar}
          toggleCollapseSidebar={toggleCollapseSidebar}
          collapsedSidebar={collapsedSidebar}
        />
        <section className="orty-layout">
          <Sidebar
            collapsedSidebar={collapsedSidebar}
            closeSidebar={closeSidebar}
            isOpen={isOpen}
            activeItem={""}
          />
          <section
            className={`orty-layout orty-main-layout${collapsedSidebar ? " orty-main-collapsed-layout" : ""}${!isOpen && width < 850 ? " mobile-sidebar-opened" : ""}`}
          >
            <div className="orty-layout-content">
              <WrappedComponent {...props} />
            </div>
          </section>
        </section>
      </div>
    );
  };

  return LayoutComponent;
};

export default ThemeLayout;
