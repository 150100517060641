import {
  EXTRA_CLIENT_COMMANDES_SPINNER_EN,
  EXTRA_CLIENT_COMMANDES_LIST_SUCCESS,
  EXTRA_CLIENT_COMMANDES_SPINNER_DIS,
  ADD_EXTRA_CLIENT_COMMANDES_SPINNER_DIS,
  ADD_EXTRA_CLIENT_COMMANDES_SPINNER_EN,
  ADD_EXTRA_CLIENT_SUCCESS,
} from "../types";

const initialState = {
  commandesCX: [],
  getCommandeCXLoader: false,
  addCommandeCXLoader: false,
};

export default function clientExtraCommandeReducer(state = initialState, action) {
  switch (action.type) {
    case EXTRA_CLIENT_COMMANDES_SPINNER_EN:
      return {
        ...state,
        getCommandeCXLoader: true,
      };
    case EXTRA_CLIENT_COMMANDES_SPINNER_DIS:
      return { ...state, getCommandeCXLoader: false };
    case EXTRA_CLIENT_COMMANDES_LIST_SUCCESS:
      return {
        ...state,
        commandesCX: action.payload,
      };
    case ADD_EXTRA_CLIENT_COMMANDES_SPINNER_EN:
      return {
        ...state,
        addCommandeCXLoader: true,
      };
    case ADD_EXTRA_CLIENT_COMMANDES_SPINNER_DIS:
      return {
        ...state,
        addCommandeCXLoader: false,
      };
    case ADD_EXTRA_CLIENT_SUCCESS:
      return {
        ...state,
        commandesCX: [...state.commandesCX, action.payload],
      };
    default:
      return state;
  }
}
