import {
  ADD_SPONSORING_SPINNER_DIS,
  ADD_SPONSORING_SPINNER_EN,
  ADD_SPONSORING_SUCCESS,
  GET_SPONSORING_SPINNER_DIS,
  GET_SPONSORING_SPINNER_EN,
  GET_SPONSORING_SUCCESS,
  UPDATE_SPONSORING_SUCCESS,
} from "../types";

const initialState = {
  sponsorings: [],
  add_sponsors_spinner: false,
  get_sponsors_spinner: false,
};

export default function sponsorReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SPONSORING_SUCCESS:
      const { sponsoring, sponsoringId } = action.payload;
      const updatedSponsoring = state.sponsorings.map((s) =>
        s.id === sponsoringId ? sponsoring : s
      );
      return {
        ...state,
        sponsorings: updatedSponsoring,
      };
    case GET_SPONSORING_SPINNER_EN:
      return {
        ...state,
        get_sponsors_spinner: true,
      };
    case GET_SPONSORING_SPINNER_DIS:
      return {
        ...state,
        get_sponsors_spinner: false,
      };
    case GET_SPONSORING_SUCCESS:
      return {
        ...state,
        sponsorings: action.payload,
      };
    case ADD_SPONSORING_SPINNER_EN:
      return {
        ...state,
        add_sponsors_spinner: true,
      };
    case ADD_SPONSORING_SPINNER_DIS:
      return {
        ...state,
        add_sponsors_spinner: false,
      };
    case ADD_SPONSORING_SUCCESS:
      return {
        ...state,
        sponsorings: [...state.sponsorings, action.payload],
      };
    default:
      return state;
  }
}
