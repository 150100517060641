import React, { lazy, Suspense, useEffect } from "react";
import { Row, Col, Skeleton, Spin } from "antd";
import { useRoutes } from "react-router-dom";
import { SettingWrapper } from "./style";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Cards } from "../../components/cards/frame/cards-frame";
import { getSelfUser } from "../../redux/actions/user.action";
import { useDispatch, useSelector } from "react-redux";

const EditProfile = lazy(() => import("./EditProfile"));
const Password = lazy(() => import("./Password"));
const AuthorBox = lazy(() => import("./AuthBox"));

function Profile() {
  const dispatch = useDispatch();
  const {
    self_loader,
    self_profile,
    edit_password_loader,
    edit_profile_loader,
  } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getSelfUser());
  }, [dispatch]);

  const routes = [
    {
      path: "password",
      element: <Password edit_password_loader={edit_password_loader} />,
    },
    {
      path: "editProfile",
      element: (
        <EditProfile
          self_loader={self_loader}
          edit_profile_loader={edit_profile_loader}
          self_profile={self_profile}
        />
      ),
    },
    {
      path: "*",
      element: (
        <EditProfile
          self_loader={self_loader}
          edit_profile_loader={edit_profile_loader}
          self_profile={self_profile}
        />
      ),
    }, // Default route to List component
  ];

  const element = useRoutes(routes);

  return (
    <>
      <PageHeader ghost title="Paramètres de profil" />

      <div className="orty-content-sd">
        <Row gutter={25}>
          <Col xxl={6} lg={8} md={10} xs={24}>
            <Suspense
              fallback={
                <Cards headless>
                  <Skeleton paragraph={{ rows: 20 }} />
                </Cards>
              }
            >
              <AuthorBox
                self_loader={self_loader}
                self_profile={self_profile}
              />
            </Suspense>
          </Col>
          <Col xxl={18} lg={16} md={14} xs={24}>
            <SettingWrapper>
              <Suspense
                fallback={
                  <div className="spin">
                    <Spin />
                  </div>
                }
              >
                {element}
              </Suspense>
            </SettingWrapper>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Profile;
