import {
  ADD_DELIVERY_COMPANY_SPINNER_DIS,
  ADD_DELIVERY_COMPANY_SPINNER_EN,
  ADD_DELIVERY_COMPANY_SUCCESS,
  ADD_SWITCH_ID_TO_LOADER,
  DELETE_DC_SPINNER_DIS,
  DELETE_DC_SPINNER_EN,
  DELETE_DC_SUCCESS,
  GET_DELIVERY_COMPANY_SPINNER_DIS,
  GET_DELIVERY_COMPANY_SPINNER_EN,
  GET_DELIVERY_COMPANY_SUCCESS,
  REMOVE_SWITCH_ID_FROM_LOADER,
  RESTORE_DC_SPINNER_DIS,
  RESTORE_DC_SPINNER_EN,
  RESTORE_DC_SUCCESS,
  UPDATE_DELIEVERED_COMPANY_SUCCESS,
} from "../types";
const initialState = {
  dcompanies: [],
  add_dc_spinner: false,
  get_dc_spinner: false,
  delete_dc_spinner: false,
  switch_dcIds: [],
};

export default function dcompanyReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SWITCH_ID_TO_LOADER:
      if (!state.switch_dcIds.includes(action.id)) {
        return {
          ...state,
          switch_dcIds: [...state.switch_dcIds, action.id],
        };
      }
      return state;

    case REMOVE_SWITCH_ID_FROM_LOADER:
      return {
        ...state,
        switch_dcIds: state.switch_dcIds.filter((id) => id !== action.id),
      };

    case DELETE_DC_SUCCESS:
      const { x, xid } = action.payload;
      const uc = state.dcompanies.map((dc) => (dc.id === xid ? x : dc));
      return {
        ...state,
        dcompanies: uc,
      };
    case DELETE_DC_SPINNER_EN:
      return {
        ...state,
        delete_dc_spinner: true,
      };
    case DELETE_DC_SPINNER_DIS:
      return {
        ...state,
        delete_dc_spinner: false,
      };
    case RESTORE_DC_SPINNER_EN:
      return {
        ...state,
        delete_dc_spinner: true,
      };
    case RESTORE_DC_SPINNER_DIS:
      return {
        ...state,
        delete_dc_spinner: false,
      };
    case RESTORE_DC_SUCCESS:
      const { y, yid } = action.payload;
      const yc = state.dcompanies.map((dc) => (dc.id === yid ? y : dc));
      return {
        ...state,
        dcompanies: yc,
      };
    case UPDATE_DELIEVERED_COMPANY_SUCCESS:
      const { company, companyId } = action.payload;
      const updatedCompany = state.dcompanies.map((dc) =>
        dc.id === companyId ? company : dc
      );
      return {
        ...state,
        dcompanies: updatedCompany,
      };
    case GET_DELIVERY_COMPANY_SPINNER_EN:
      return {
        ...state,
        get_dc_spinner: true,
      };
    case GET_DELIVERY_COMPANY_SPINNER_DIS:
      return {
        ...state,
        get_dc_spinner: false,
      };
    case GET_DELIVERY_COMPANY_SUCCESS:
      return {
        ...state,
        dcompanies: action.payload,
      };
    case ADD_DELIVERY_COMPANY_SPINNER_DIS:
      return {
        ...state,
        add_dc_spinner: false,
      };
    case ADD_DELIVERY_COMPANY_SPINNER_EN:
      return {
        ...state,
        add_dc_spinner: true,
      };
    case ADD_DELIVERY_COMPANY_SUCCESS:
      return {
        ...state,
        dcompanies: [...state.dcompanies, action.payload],
      };
    default:
      return state;
  }
}
