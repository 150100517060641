import React, { memo } from "react";
import {
  CiDeliveryTruck,
  CiShoppingBasket,
  CiBoxes,
  CiCalculator1,
  CiDollar,
  CiShop,
} from "react-icons/ci";
import {
  PiHandHeartThin,
  PiMoneyThin,
  PiUsersThreeThin,
  PiUserCircleThin,
  PiArchiveBoxLight,
  PiChartLineThin,
  PiUserThin,
} from "react-icons/pi";
import { LuKanbanSquareDashed } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { collapseSidebarElement } from "../../redux/actions/app.action";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { Popover } from "../Popup/popup";
import { Content } from "../Popup/style";

const renderAdditionalContent = (value, active, collapsed) => {
  switch (value) {
    case "Achat":
      return (
        <PiMoneyThin
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    case "Dashboard":
      return (
        <LuKanbanSquareDashed
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    case "Commande client":
      return (
        <CiShoppingBasket
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    case "Colis":
      return (
        <CiBoxes
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    case "Stock":
      return (
        <PiArchiveBoxLight
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    case "Bon des sorties":
      return (
        <CiCalculator1
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    case "Société de livraison":
      return (
        <CiDeliveryTruck
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    case "Paiement":
      return (
        <CiDollar
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    case "Fournisseur":
      return (
        <PiUserCircleThin
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    case "Clients extra":
      return (
        <PiUserThin
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    case "Statistique et gain":
      return (
        <PiChartLineThin
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    case "Vos boutiques":
      return (
        <CiShop
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    case "Gestion d'équipe":
      return (
        <PiUsersThreeThin
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    case "Nos offres":
      return (
        <PiHandHeartThin
          className={`sidebar-svg ${active ? "activeSvg" : ""} ${
            collapsed && "collapsedSvg"
          }`}
        />
      );
    default:
      return null;
  }
};

const SidebarElement = ({
  isActive,
  title,
  path,
  hasChild,
  items,
  collapseChilds,
  activeItem,
  collapsedSidebar,
}) => {
  const { mine_permissions } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);

  const hasPermission = (title) => {
    return mine_permissions?.some(
      (permission) =>
        permission.name === title && permission.permissions.includes("FETCH")
    );
  };

  const isAdmin = user?.role === "ADMIN";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hasParentPermission = isAdmin || hasPermission(title);
  const hasChildPermission = items?.some(
    (item) => isAdmin || hasPermission(item.title)
  );

  if (!hasParentPermission && !hasChildPermission) {
    return null;
  }

  return (
    <li className="sidebar-elems-item" style={{ display: "block" }}>
      <div style={{ width: "100%" }}>
        <span
          onClick={() => {
            if (!collapsedSidebar) {
              if (hasChild) {
                dispatch(collapseSidebarElement(title));
              } else {
                navigate(path);
              }
            }
          }}
          style={{ cursor: collapsedSidebar ? "auto" : "pointer" }}
          className={`sidebar-click-item ${isActive ? "activeLinkTest" : ""} ${
            collapsedSidebar && "collapsed-sidebar-click-item"
          }`}
        >
          <div id="top"></div>
          {collapsedSidebar ? (
            <Popover
              content={
                <Content
                  className="chilhv"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (hasChild) {
                      navigate(path);
                    } else {
                      navigate(path);
                    }
                  }}
                >
                  <span
                    className={`${
                      hasChild ? "hasChild-Popover" : "noHasChildPopover"
                    }`}
                  >
                    {title}
                  </span>
                  {hasChild && (
                    <div className="sidebar-children-popover">
                      {items
                        ?.filter((item) => isAdmin || hasPermission(item.title))
                        .map((item, index) => (
                          <div
                            key={`popover_child_${index}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(item.path);
                            }}
                            className="noHasChildPopover"
                          >
                            {item.title}
                          </div>
                        ))}
                    </div>
                  )}
                </Content>
              }
              placement={"right"}
            >
              <div
                className={`sidebar-item-icon ${
                  isActive ? "sb-activeIcon" : ""
                }`}
              >
                {renderAdditionalContent(title, isActive, collapsedSidebar)}
              </div>
            </Popover>
          ) : (
            <div
              className={`sidebar-item-icon ${isActive ? "sb-activeIcon" : ""}`}
            >
              {renderAdditionalContent(title, isActive, collapsedSidebar)}
            </div>
          )}
          {!collapsedSidebar && (
            <div className="sidebar-elem-text-container">
              <p
                className={`sidebar-elem-p ${isActive ? "sb-textActive" : ""}`}
              >
                {title}
              </p>
            </div>
          )}
          <div id="bottom"></div>
          <span className="MuiTouchRipple-root css-w0pj6f">
            {!collapsedSidebar && hasChild ? (
              collapseChilds.includes(title) ? (
                <FaChevronDown size={12} color="rgb(170 166 160)" />
              ) : (
                <FaChevronRight size={12} color="rgb(170 166 160)" />
              )
            ) : null}
          </span>
        </span>
      </div>
      {hasChild && (
        <div
          className={`sidebar-children ${
            collapseChilds.includes(title) ? "sidebar-children-expanded" : ""
          }`}
        >
          {hasChild &&
            collapseChilds.includes(title) &&
            items
              ?.filter((item) => isAdmin || hasPermission(item.title))
              .map((item, index) => {
                const isChildActive = activeItem === item?.path;
                return (
                  <Link
                    key={`child_${index}`}
                    to={`${item?.path}`}
                    className={`sidebar-click-item ${
                      isChildActive ? "activeLinkTest" : ""
                    }`}
                  >
                    <div className="sidebar-elem-text-container">
                      <p
                        className={`sidebar-elem-p 
                        ${isChildActive ? "sb-textActive" : ""}
                        `}
                      >
                        {item?.title}
                      </p>
                    </div>
                    <div id="bottom"></div>
                  </Link>
                );
              })}
        </div>
      )}
    </li>
  );
};

export default memo(SidebarElement);
