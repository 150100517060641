import React, { useEffect } from "react";
import DashboardImage from "../../assets/landing/Dashboard_2x.png";
import ColisImage from "../../assets/landing/colislist.png";
const HeroAnimation = () => {
  return (
    <div
      id="heroanimation_hype_container"
      class="HYPE_document"
      style={{
        margin: "auto",
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        transformStyle: "flat",
      }}
      hyp_dn="HeroAnimation"
    >
      <div
        id="HYPE_persistent_symbols"
        aria-hidden="true"
        style={{ display: "none" }}
      ></div>
      <div
        class="HYPE_scene"
        id="hype-scene-SZFDTJQXMB8UUV4AEREB"
        hype_scene_index="0"
        style={{
          display: "block",
          overflow: "hidden",
          position: "absolute",
          width: "100%",
          top: "0px",
          left: "0px",
          height: "100%",
          transformOrigin: "50% 50%",
          zIndex: 1,
          opacity: 1,
        }}
      >
        <div
          class="HYPE_element_container"
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            perspective: "600px",
            pointerEvents: "none",
            zIndex: 1,
          }}
        >
          <div
            class="HYPE_element OTY_DASH_element"
            id="hype-obj-VN87W918HG62D9L3HNW8"
            hype_scene_index="0"
            style={{
              pointerEvents: "auto",
              position: "absolute",
              overflow: "visible",
              transformOrigin: "50% 50%",
              //   transform: "translateX(29px) translateY(27px) rotateY(-10deg)",
              zIndex: 1,
              width: "526px",
              height: "395px",
            }}
          >
            <div
              class="HYPE_element_container"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                perspective: "600px",
                pointerEvents: "none",
                zIndex: 1,
              }}
            >
              <div
                class="HYPE_element"
                id="hype-obj-K5GLT77V46QMS9E5FTI1"
                hype_scene_index="0"
                role="img"
                style={{
                  pointerEvents: "auto",
                  position: "absolute",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  display: "inline",
                  transformOrigin: "50% 50%",
                  //   transform: "translateX(-194px) translateY(-140px) scaleX(0.5) scaleY(0.5)",
                  backgroundImage: `url("${DashboardImage}")`,
                  overflow: "visible",
                  zIndex: 1,
                  filter: "none",
                  width: "486px",
                  height: "345px",
                }}
              ></div>
            </div>
            <div
              class="HYPE_element_container"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                pointerEvents: "none",
                zIndex: 2,
              }}
            >
              <div
                class="HYPE_element"
                id="hype-obj-4GXAP4G6IOOY44DEG33Y"
                hype_scene_index="0"
                style={{
                  pointerEvents: "auto",
                  position: "absolute",
                  overflow: "hidden",
                  borderRadius: "4px",
                  backdropFilter: "none",
                  zIndex: 2,
                  opacity: 0.8,
                  width: "326px",
                  height: "141px",
                  transformOrigin: "50% 50%",
                  transform: "translateX(135px) translateY(90px) rotateY(0deg)",
                }}
              >
                <div
                  class="HYPE_element_container"
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    height: "100%",
                    perspective: "600px",
                    pointerEvents: "none",
                    zIndex: 1,
                  }}
                >
                  <div
                    class="HYPE_element ORY_COLIS_ELEM"
                    id="hype-obj-Q6U18WOBH13MAPRQAYOE"
                    hype_scene_index="0"
                    role="img"
                    style={{
                      pointerEvents: "auto",
                      transformOrigin: "50% 50%",
                      transform:
                        "translateX(-52px) translateY(-66px) scaleX(0.8) scaleY(0.45)",
                      position: "absolute",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% 100%",
                      display: "inline",
                      //   opacity: 0.8,
                      backdropFilter: "none",
                      backgroundImage: `url("${ColisImage}")`,
                      overflow: "visible",
                      zIndex: 1,
                      boxShadow: "rgba(0, 0, 0, 0) 0px 0px 20px",
                      width: "430px",
                      height: "271px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="HYPE_element_container"
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            perspective: "600px",
            pointerEvents: "none",
            zIndex: 9,
          }}
        >
          <div
            class="HYPE_element ORTY_BACKDROP"
            id="hype-obj-0PNDW9Q1J0MQ5SH65NZ7"
            hype_scene_index="0"
            style={{
              pointerEvents: "auto",
              transformOrigin: "50% 50%",
              //   transform:`
              //   'translateX(158.82px) translateY(128.5px) rotateY(1deg) scaleX(1) scaleY(1)',
              position: "absolute",
              display: "inline",
              //   opacity: 1,
              backdropFilter: "blur(6.84027px)",
              backgroundColor: "rgb(255 152 124 / 71%)",
              borderRadius: "4px",
              overflow: "hidden",
              zIndex: 9,
              //   boxShadow: "rgba(0, 0, 0, 0.204) 0px 0px 20px",
              //   width: "338px",
              //   height: "117px",
            }}
          >
            <div
              class="HYPE_element_container"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                perspective: "600px",
                pointerEvents: "none",
                zIndex: 2,
              }}
            >
              <div
                class="HYPE_element ORTY_COLIS_ELEM"
                id="hype-obj-4IVQY02LBWH3RDRN4RNV"
                hype_scene_index="0"
                role="img"
                // aria-hidden="true"
                style={{
                  pointerEvents: "auto",
                  transformOrigin: "50% 50%",
                  position: "absolute",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  display: "inline",
                  //   opacity: 1,
                  transform:
                    "translateX(-42.5px) translateY(-75px) scaleX(0.818) scaleY(0.46)",
                  backgroundImage: `url("${ColisImage}")`,
                  overflow: "visible",
                  zIndex: 2,
                  boxShadow: "rgba(0, 0, 0, 0) 0px 0px 20px",
                  width: "422px",
                  height: "266px",
                }}
              ></div>
            </div>
            <div
              class="HYPE_element_container"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                perspective: "600px",
                pointerEvents: "none",
                zIndex: 4,
              }}
            >
              <div
                class="HYPE_element ORTY_TEXT_ELEM"
                id="hype-obj-A1WLRJZ9Y3LE70185P3H"
                hype_scene_index="0"
                style={{
                  pointerEvents: "auto",
                  position: "absolute",
                  padding: "8px",
                  color: "rgb(255, 255, 255)",
                  display: "inline",
                //   opacity: 1,
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "18px",
                  overflowWrap: "break-word",
                  fontWeight: 400,
                  transformOrigin: "50% 50%",
                  transform: "translateX(15px) translateY(3px) rotateY(180deg)",
                  overflow: "visible",
                  whiteSpace: "nowrap",
                  zIndex: 4,
                  textShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
                  textAlign: "center",
                }}
              >
                <span style={{ fontWeight: "500", fontSize: 16 }}>Gérez{" "}</span>
                
                <span style={{ fontSize: 14 }}>
                  Vos Colis
                  <br />
                  Facilement
                </span>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroAnimation;
