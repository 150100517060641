import {
  ADD_ATTRIBUTE_OPTION_SPINNER_DIS,
  ADD_ATTRIBUTE_OPTION_SPINNER_EN,
  ADD_ATTRIBUTE_OPTION_SUCCESS,
  ADD_ATTRIBUTE_VALUE_SPINNER_DIS,
  ADD_ATTRIBUTE_VALUE_SPINNER_EN,
  ADD_ATTRIBUTE_VALUE_SUCCESS,
  GET_ATTRIBUTES_SPINNER_DIS,
  GET_ATTRIBUTES_SPINNER_EN,
  GET_ATTRIBUTES_SUCCESS,
} from "../types";

const initialState = {
  attributes: [
    {
      option: "Taille",
      values: ["S", "M", "L", "XL", "XXL"],
    },
    {
      option: "Couleurs",
      values: ["Rouge", "Noir", "Bleu", "Vert"],
    },
  ],
  get_attributes_loader: false,
  add_option_attribute_loader: false,
  add_option_value_loader: false,
};

export default function attributeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ATTRIBUTES_SPINNER_EN:
      return {
        ...state,
        get_attributes_loader: true,
      };
    case GET_ATTRIBUTES_SPINNER_DIS:
      return {
        ...state,
        get_attributes_loader: false,
      };
    case GET_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        attributes: action.payload,
      };
    case ADD_ATTRIBUTE_OPTION_SUCCESS:
      return {
        ...state,
        attributes: [
          ...state.attributes,
          {
            option: action.payload,
            values: [],
          },
        ],
      };

    case ADD_ATTRIBUTE_VALUE_SUCCESS:
      const { option, value } = action.payload;
      const optionIndex = state.attributes.findIndex(
        (attr) => attr.option === option
      );
      if (optionIndex !== -1) {
        return {
          ...state,
          attributes: [
            ...state.attributes.slice(0, optionIndex),
            {
              ...state.attributes[optionIndex],
              values: [...state.attributes[optionIndex].values, value],
            },
            ...state.attributes.slice(optionIndex + 1),
          ],
        };
      } else {
        return state;
      }

    case ADD_ATTRIBUTE_OPTION_SPINNER_EN:
      return {
        ...state,
        add_option_attribute_loader: true,
      };
    case ADD_ATTRIBUTE_OPTION_SPINNER_DIS:
      return {
        ...state,
        add_option_attribute_loader: false,
      };
    case ADD_ATTRIBUTE_VALUE_SPINNER_EN:
      return {
        ...state,
        add_option_value_loader: true,
      };
    case ADD_ATTRIBUTE_VALUE_SPINNER_DIS:
      return {
        ...state,
        add_option_value_loader: false,
      };
    default:
      return state;
  }
}
