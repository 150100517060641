import {
  ADD_BOUTIQUE_MODAL_CLOSE,
  ADD_BOUTIQUE_MODAL_OPEN,
  ADD_FOURNISSEUR_MODAL_CLOSE,
  ADD_FOURNISSEUR_MODAL_OPEN,
  ADD_LIVRED_COMPANY_CLOSE_MODAL,
  ADD_LIVRED_COMPANY_OPEN_MODAL,
  ADD_PAYMENT_CLOSE_MODAL,
  ADD_PAYMENT_OPEN_MODAL,
  ADD_SPONSORING_MODAL_CLOSE,
  ADD_SPONSORING_MODAL_OPEN,
  ADD_STOCK_CAT_MODAL_CLOSE,
  ADD_STOCK_CAT_MODAL_OPEN,
  CHARGE_MODAL_CLOSE,
  CHARGE_MODAL_OPEN,
  COLLAPSE_SIDEBAR_ELEMENTS,
  MODAL_CLOSE_ADD_CLIENT_EXTRA,
  MODAL_OPEN_ADD_CLIENT_EXTRA,
  UPDATE_LOADER_DIS,
  UPDATE_LOADER_EN,
  UPDATE_MODAL_CLOSE,
  UPDATE_MODAL_OPEN,
} from "../types";

const initialState = {
  collapseChilds: [],
  addFournisseurModal: false,
  addClientModal: false,
  addCatModal: false,
  addCompanyLivrisonModal: false,
  addBoutiqueModal: false,
  sponsoringModal: false,
  paymentModal: false,
  achatModal: false,
  updateLoader: false,
  updateModal: {
    modal: false,
    data: null,
  },
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case MODAL_OPEN_ADD_CLIENT_EXTRA:
      return {
        ...state,
        addClientModal: true,
      };
    case MODAL_CLOSE_ADD_CLIENT_EXTRA:
      return {
        ...state,
        addClientModal: false,
      };
    case UPDATE_LOADER_EN:
      return {
        ...state,
        updateLoader: true,
      };
    case UPDATE_LOADER_DIS:
      return {
        ...state,
        updateLoader: false,
      };
    case UPDATE_MODAL_OPEN:
      return {
        ...state,
        updateModal: {
          modal: true,
          data: action.payload,
        },
      };
    case UPDATE_MODAL_CLOSE:
      return {
        ...state,
        updateModal: {
          modal: false,
          data: null,
        },
      };
    case CHARGE_MODAL_CLOSE:
      return {
        ...state,
        achatModal: false,
      };
    case CHARGE_MODAL_OPEN:
      return {
        ...state,
        achatModal: true,
      };
    case ADD_PAYMENT_OPEN_MODAL:
      return {
        ...state,
        paymentModal: true,
      };
    case ADD_PAYMENT_CLOSE_MODAL:
      return {
        ...state,
        paymentModal: false,
      };
    case ADD_BOUTIQUE_MODAL_OPEN:
      return {
        ...state,
        addBoutiqueModal: true,
      };
    case ADD_BOUTIQUE_MODAL_CLOSE:
      return {
        ...state,
        addBoutiqueModal: false,
      };
    case ADD_LIVRED_COMPANY_OPEN_MODAL:
      return {
        ...state,
        addCompanyLivrisonModal: true,
      };
    case ADD_LIVRED_COMPANY_CLOSE_MODAL:
      return {
        ...state,
        addCompanyLivrisonModal: false,
      };
    case ADD_STOCK_CAT_MODAL_OPEN:
      return {
        ...state,
        addCatModal: true,
      };
    case ADD_STOCK_CAT_MODAL_CLOSE:
      return {
        ...state,
        addCatModal: false,
      };
    case ADD_SPONSORING_MODAL_OPEN:
      return {
        ...state,
        sponsoringModal: true,
      };
    case ADD_SPONSORING_MODAL_CLOSE:
      return {
        ...state,
        sponsoringModal: false,
      };
    case ADD_FOURNISSEUR_MODAL_OPEN:
      return {
        ...state,
        addFournisseurModal: true,
      };
    case ADD_FOURNISSEUR_MODAL_CLOSE:
      return {
        ...state,
        addFournisseurModal: false,
      };
    case COLLAPSE_SIDEBAR_ELEMENTS:
      const { title } = action.payload;
      const isAlreadyCollapsed = state.collapseChilds.includes(title);

      return {
        ...state,
        collapseChilds: isAlreadyCollapsed ? [] : [title],
      };
    default:
      return state;
  }
}
