import React, { useState } from "react";
import LogoOrtyAcceuil from "../../assets/img/logo-orty-acceuil.png";
import { OrtyBars } from "../../utils/icons";
import { Button } from "../Button/Buttons";
import { useNavigate } from "react-router-dom";

const MobileNavbar = () => {
  const navigate = useNavigate();
  const [isOpenNavbar, setisOpenNavbar] = useState(false);
  const toggleOpenNavbar = () => {
    setisOpenNavbar(!isOpenNavbar);
  };
  return (
    <div
      data-animation="default"
      data-collapse="all"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      data-doc-height="1"
      role="banner"
      class="mobile-navbar w-nav"
    >
      <div class="container-6 w-container">
        <a
          href="/"
          aria-current="page"
          class="w-nav-brand w--current"
          aria-label="home"
        >
          <img alt="" loading="lazy" src={LogoOrtyAcceuil} class="logo-mb" />
        </a>
        <nav role="navigation" class="nav-menu w-nav-menu">
          <div
            data-delay="0"
            data-hover="false"
            class="dropdown w-dropdown"
            style={{ maxWidth: "940px" }}
          >
            <div
              class="w-dropdown-toggle"
              id="w-dropdown-toggle-1"
              aria-controls="w-dropdown-list-1"
              aria-haspopup="menu"
              aria-expanded="false"
              role="button"
              tabindex="0"
            >
              <div class="w-icon-dropdown-toggle" aria-hidden="true"></div>
              <div class="text-block-20">Product</div>
            </div>
            <nav
              class="dropdown-list-2 w-dropdown-list"
              id="w-dropdown-list-1"
              aria-labelledby="w-dropdown-toggle-1"
            >
              <a
                href="/why-measure-brand-performance"
                class="w-dropdown-link"
                tabindex="0"
              >
                Why measure brand performance?
              </a>
              <a
                href="/which-brand-metrics-matter"
                class="w-dropdown-link"
                tabindex="0"
              >
                Which metrics should I be tracking
              </a>
              <a
                href="/how-does-latana-generate-insights"
                class="w-dropdown-link"
                tabindex="0"
              >
                How does Latana generate brand insights?
              </a>
              <a href="/use-cases" class="w-dropdown-link" tabindex="0">
                Use Cases
              </a>
            </nav>
          </div>
          <div
            data-delay="0"
            data-hover="false"
            class="dropdown-2 w-dropdown"
            style={{ maxWidth: 940 }}
          >
            <div
              class="w-dropdown-toggle"
              id="w-dropdown-toggle-2"
              aria-controls="w-dropdown-list-2"
              aria-haspopup="menu"
              aria-expanded="false"
              role="button"
              tabindex="0"
            >
              <div class="w-icon-dropdown-toggle" aria-hidden="true"></div>
              <div class="text-block-21">Why Latana</div>
            </div>
            <nav
              class="dropdown-list-2 w-dropdown-list"
              id="w-dropdown-list-2"
              aria-labelledby="w-dropdown-toggle-2"
            >
              <a
                href="/why-latana/better-data"
                class="w-dropdown-link"
                tabindex="0"
              >
                Better data
              </a>
              <a
                href="/why-latana/easy-to-use"
                class="w-dropdown-link"
                tabindex="0"
              >
                Easy to use
              </a>
            </nav>
          </div>
          <a
            href="/pricing"
            class="nav-link w-nav-link"
            style={{ maxWidth: 940 }}
          >
            Pricing
          </a>
          <a
            href="https://resources.latana.com/articles/"
            class="nav-link-2 w-nav-link"
            style={{ maxWidth: 940 }}
          >
            Resources
          </a>
          <a
            href="https://app.latana.com/login"
            class="nav-link-3 w-nav-link"
            style={{ maxWidth: 940 }}
          >
            Login
          </a>
          <a
            data-w-id="fcf45e15-f05c-8707-2e61-6acbcd10cce0"
            href="#"
            class="button w-button"
          >
            Book a demo
          </a>
        </nav>
        <div
          onClick={toggleOpenNavbar}
          class="menu-button w-nav-button"
          style={{ padding: "5px 15px" }}
          //   style="-webkit-user-select: text;"
          aria-label="menu"
          role="button"
          tabindex="0"
          aria-controls="w-nav-overlay-0"
          aria-haspopup="menu"
          aria-expanded="false"
        >
          <OrtyBars />
        </div>
      </div>
      <div
        style={{
          height: isOpenNavbar ? "7640px" : "auto",
          display: isOpenNavbar ? "block" : "none",
        }}
        class="w-nav-overlay"
        data-wf-ignore=""
        id="w-nav-overlay-0"
      >
        <nav
          role="navigation"
          class={`nav-menu w-nav-menu ${
            isOpenNavbar ? "orty-nav-open" : "orty-nav-closed"
          }`}
          //   style={{
          //     // height: isOpenNavbar ? "7640px" : "auto",
          //     transform: `translateY(${
          //       isOpenNavbar ? "0px" : "-432px"
          //     }) translateX(0px)`,
          //     transition: "transform 400ms ease 0s",
          //   }}
          data-nav-menu-open=""
        >
          <a
            onClick={() => navigate("/login")}
            class="nav-link-3 w-nav-link w--nav-link-open"
          >
            Login
          </a>
          <Button
            onClick={() => {
              navigate("/register");
            }}
            style={{
              borderRadius: 30,
              // marginTop: 10,
              fontSize: 15,
              padding: "23px",
            }}
            size="default"
            type="primary"
          >
            Essai Gratuit
          </Button>
        </nav>
      </div>
    </div>
  );
};

export default MobileNavbar;
