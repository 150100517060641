import React, { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import OrtyInput from "../components/OrtyInput/OrtyInput";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Row } from "react-bootstrap";
import { Spin } from "antd";
import { registerOwnerAction } from "../redux/actions/user.action";
import { toast } from "react-toastify";
import LogoAcceuil from "../assets/img/logo-orty-acceuil.png";
import { Button } from "../components/Button/Buttons";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 18, color: "white" }} />;
const Register = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control, getValues } = useForm({
    mode: "onBlur",
  });
  const [privacyAccepted, setprivacyAccepted] = useState(false);
  const [privacyError, setprivacyError] = useState(null);
  const [showedPassword, setshowedPassword] = useState(true);
  const { register_spinner } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const onSubmit = (data) => {
    if (!privacyAccepted) {
      toast.error("Veuillez accepter les conditions avant de soumettre.");
    } else {
      const body = {
        firstName: data["firstname"],
        lastname: data["lastname"],
        // fullName: `${data["firstname"]} ${data["lastname"]}`,
        email: data["email"],
        phoneNumber: data["phone"],
        login: `${data["login"]}@orty.com`,
        password: data["password"],
        storeName: data["boutiqueName"],
      };
      // console.log(body);
      dispatch(registerOwnerAction(body, navigate));
    }
  };

  return (
    <div className="content-panel-hero background-gradient-light home">
      <div className="content flush-left w-container">
        <div className="panel-text mtopmobile fade-in-up">
          <div
            className="pricing-package"
            style={{ maxWidth: "630px", width: "100%", borderRadius: 30 }}
          >
            <div className="card-login-header">
              <img
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
                alt="logo Orty suivre"
                src={LogoAcceuil}
                width="68"
              />
            </div>
            <div className="card-login-body">
              <div
                className="package-title"
                style={{ fontSize: 14, fontWeight: "500", marginTop: 0 }}
              >
                Créez votre compte d’essai gratuit:
              </div>
              <p
                style={{
                  marginTop: 0,
                  fontSize: 11,
                  fontWeight: "300",
                  color: "#9f9f9f",
                }}
                className="card-login-typography"
              >
                Gérez efficacement votre stock, vos commandes, et vos colis avec
                Orty. Essayez gratuitement pendant 14 jours et simplifiez la
                gestion de votre e-commerce.
              </p>
            </div>

            <Row>
              <Col md={6} xs={12}>
                <span className="label-reg">Prénom</span>
                <Controller
                  name="firstname"
                  control={control}
                  rules={{
                    required: "Prénom est obligatoire",
                  }}
                  render={({ field, fieldState }) => (
                    <OrtyInput
                      name={"firstname"}
                      props={{
                        placeholder: "Votre Prénom",
                        ...field,
                      }}
                      isError={fieldState?.error}
                      errorMessage={fieldState?.error?.message}
                    />
                  )}
                />
              </Col>
              <Col md={6} xs={12}>
                <span className="label-reg">Nom</span>
                <Controller
                  name="lastname"
                  control={control}
                  rules={{
                    required: "Nom est obligatoire",
                  }}
                  render={({ field, fieldState }) => (
                    <OrtyInput
                      name={"lastname"}
                      props={{
                        placeholder: "Votre Nom",
                        ...field,
                      }}
                      isError={fieldState?.error}
                      errorMessage={fieldState?.error?.message}
                    />
                  )}
                />
              </Col>
            </Row>
            <span className="label-reg">Adresse email</span>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Adresse email obligatoire",
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "L'adresse email invalide.",
                },
              }}
              render={({ field, fieldState }) => (
                <OrtyInput
                  name={"email"}
                  props={{
                    placeholder: "name@example.com",
                    ...field,
                  }}
                  isError={fieldState?.error}
                  errorMessage={fieldState?.error?.message}
                />
              )}
            />
            <span className="label-reg">Nom de boutique</span>
            <Controller
              name="boutiqueName"
              control={control}
              rules={{
                required: "Le nom de boutique est obligatoire",
              }}
              render={({ field, fieldState }) => (
                <OrtyInput
                  name={"boutiqueName"}
                  props={{
                    placeholder: "Nom du boutique",
                    ...field,
                  }}
                  isError={fieldState?.error}
                  errorMessage={fieldState?.error?.message}
                />
              )}
            />
            <span className="label-reg">Login</span>
            <Controller
              name="login"
              control={control}
              rules={{
                required: "Login est obligatoire",
              }}
              render={({ field, fieldState }) => (
                <OrtyInput
                  name={"login"}
                  props={{
                    placeholder: "Boutique",
                    ...field,
                  }}
                  isError={fieldState?.error}
                  errorMessage={fieldState?.error?.message}
                  renderRightIcon={
                    <span
                      style={{
                        background: "#eee",
                        borderRadius: 10,
                        padding: "3px 20px",
                        color: "#ababab",
                        fontSize: 13,
                      }}
                    >
                      @orty.com
                    </span>
                  }
                />
              )}
            />
            <span className="label-reg">Mot de passe</span>
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Mot de passe est obligatoire",
              }}
              render={({ field, fieldState }) => (
                <OrtyInput
                  // mb={1}
                  ps={true}
                  name="password"
                  props={{
                    placeholder: "******",
                    type: showedPassword ? "password" : "text",
                    ...field,
                  }}
                  isError={fieldState?.error}
                  errorMessage={fieldState?.error?.message}
                  renderRightIcon={
                    <span
                      onClick={() => setshowedPassword(!showedPassword)}
                      style={{ cursor: "pointer" }}
                    >
                      {showedPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                    </span>
                  }
                />
              )}
            />
            <span className="label-reg">Confirmer Mot de passe</span>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: "Mot de passe est obligatoire",
                validate: (value) =>
                  value === getValues("password") ||
                  "Les mots de passe ne correspondent pas",
              }}
              render={({ field, fieldState }) => (
                <OrtyInput
                  name="confirmPassword"
                  props={{
                    placeholder: "******",
                    type: "password",
                    ...field,
                  }}
                  isError={fieldState?.error}
                  errorMessage={fieldState?.error?.message}
                />
              )}
            />
            <span className="label-reg">Numéro Téléphone</span>
            <Controller
              name="phone"
              control={control}
              rules={{
                required: "Numéro Téléphone obligatoire",
                pattern: {
                  value: /^[0-9]+$/,
                  message:
                    "Le numéro de téléphone doit contenir uniquement des chiffres.",
                },
              }}
              render={({ field, fieldState }) => (
                <OrtyInput
                  mb={3}
                  name="phone"
                  props={{
                    placeholder: "+216 22 000 000",
                    type: "text",
                    ...field,
                  }}
                  isError={fieldState?.error}
                  errorMessage={fieldState?.error?.message}
                />
              )}
            />

            <div className="d-flex">
              <Form.Check
                type={"checkbox"}
                checked={privacyAccepted}
                onChange={() => {
                  setprivacyError(null);
                  setprivacyAccepted(!privacyAccepted);
                }}
              />
              <span
                style={{
                  fontSize: 12,
                  marginLeft: 10,
                  alignSelf: "center",
                  marginTop: 1,
                  fontFamily: "Inter",
                }}
              >
                En continuant, vous acceptez{" "}
                <span style={{ color: "#ec6855", cursor: "pointer" }}>
                  les conditions d'utilisation
                </span>{" "}
                et la
                <span style={{ color: "#ec6855", cursor: "pointer" }}>
                  {" "}
                  politique de confidentialité
                </span>{" "}
                d'orty.
              </span>
            </div>
            <span
              style={{
                color: "#f5365c",
                fontFamily: "Inter",
                fontSize: "0.75rem",
              }}
            >
              {privacyError}
            </span>
            <div className="d-flex mt-2" style={{ justifyContent: "center" }}>
              <Button
                onClick={handleSubmit(onSubmit)}
                style={{
                  borderRadius: 30,
                  marginTop: 10,
                  fontSize: 15,
                  padding: "23px",
                }}
                size="default"
                type="primary"
              >
                {register_spinner ? <Spin indicator={antIcon} /> : "S'inscrire"}
              </Button>
            </div>
            <div className="d-flex mt-3" style={{ justifyContent: "center" }}>
              <div
                style={{
                  fontSize: 13,
                  color: "rgba(58, 53, 65, 0.68)",
                  fontFamily: "Inter",
                  fontWeight: "400",
                }}
              >
                <span>Vous avez déjà un compte?</span>
                <span
                  onClick={() => {
                    navigate("/login");
                  }}
                  style={{
                    marginLeft: 8,
                    color: "#ec6855",
                    cursor: "pointer",
                  }}
                >
                  Se connecter
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
