import React from "react";

function extractFirstTwoCharacters(name) {
  return name.substring(0, 2);
}

const BoutiqueAvatar = ({
  name = "??",
  size = 120,
  fontSize = 38,
  colorText = "white",
}) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        background: "rgb(233 233 233)",
        borderRadius: 100,
        display: "flex", // Add display: flex to use Flexbox
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <span
        style={{
          textAlign: "center",
          fontSize: fontSize,
          fontFamily: "Inter",
          fontWeight: "800",
          color: colorText,
          textTransform: "uppercase",
        }}
      >
        {extractFirstTwoCharacters(name)}
      </span>
    </div>
  );
};

export default BoutiqueAvatar;
