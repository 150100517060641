import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Input } from "antd";
import { loginAction } from "../redux/actions/user.action";
import LogoAcceuil from "../assets/img/logo-orty-acceuil.png";
import { Cards } from "../components/cards/frame/cards-frame";
import { Button } from "../components/Button/Buttons";
import { LoadingOutlined } from "@ant-design/icons";
import OrtyInput from "../components/OrtyInput/OrtyInput";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const antIcon = <LoadingOutlined style={{ fontSize: 18, color: "white" }} />;

const Login = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm({ mode: "onBlur" });
  const [showedPassword, setshowedPassword] = useState(true);
  const { login_spinner } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const onSubmit = (data) => {
    dispatch(loginAction(data, navigate));
  };

  return (
    <div className="content-panel-hero background-gradient-light home">
      <div className="content flush-left w-container fade-in-up">
        <div className="panel-text mtopmobile">
          <div
            className="pricing-package"
            style={{ maxWidth: "430px", width: "100%", borderRadius: 30 }}
          >
            <div
            // style={{
            //   padding: "0.75rem 2.25rem 1.75rem",
            // }}
            >
              <div className="card-login-header">
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/")}
                  alt="logo Orty suivre"
                  src={LogoAcceuil}
                  width="68"
                />
              </div>
              <div className="card-login-body">
                <div
                  className="package-title"
                  style={{ fontSize: 14, fontWeight: "500", marginTop: 30 }}
                >
                  Se connectez
                </div>
                <p
                  style={{
                    marginTop: 0,
                    fontSize: 11,
                    fontWeight: "300",
                    color: "#9f9f9f",
                  }}
                  className="card-login-typography"
                >
                  Connectez-vous pour accéder à votre boutique et gérer vos
                  commandes, colis, et stock. Nous sommes ravis de vous revoir.
                </p>
              </div>

              {/* <span className="label-reg">Adresse email</span> */}
              <Controller
                name="login"
                control={control}
                rules={{
                  required: "Adresse email obligatoire",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "L'adresse email invalide.",
                  },
                }}
                render={({ field, fieldState }) => (
                  // <OrtyInput
                  //   name={"login"}
                  //   props={{
                  //     placeholder: "Login",
                  //     ...field,
                  //   }}
                  //   isError={fieldState?.error}
                  //   errorMessage={fieldState?.error?.message}
                  // />
                  // <>
                  //   <Input
                  //     value={field.value}
                  //     onChange={field.onChange}
                  //     style={{
                  //       padding: "11px 12px",
                  //       fontFamily: "Inter",
                  //       borderColor: fieldState?.error ? "#f5365c" : "#eaebea",
                  //     }}
                  //     placeholder={"Email"}
                  //   />
                  //   {fieldState?.error && (
                  //     <p
                  //       style={{
                  //         color: "#f5365c",
                  //         fontSize: 12,
                  //         fontFamily: "Inter",
                  //         fontWeight: "400",
                  //       }}
                  //     >
                  //       {fieldState?.error?.message}
                  //     </p>
                  //   )}
                  // </>
                  <OrtyInput
                    name={"login"}
                    props={{
                      placeholder: "boutique@orty.com",
                      ...field,
                    }}
                    isError={fieldState?.error}
                    errorMessage={fieldState?.error?.message}
                  />
                )}
              />

              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Mot de passe est obligatoire",
                  minLength: {
                    value: 6,
                    message:
                      "Le mot de passe doit comporter au moins 6 caractères",
                  },
                }}
                render={({ field, fieldState }) => (
                  <OrtyInput
                    // mb={1}
                    ps={true}
                    name="password"
                    props={{
                      placeholder: "******",
                      type: showedPassword ? "password" : "text",
                      ...field,
                    }}
                    isError={fieldState?.error}
                    errorMessage={fieldState?.error?.message}
                    renderRightIcon={
                      <span
                        onClick={() => setshowedPassword(!showedPassword)}
                        style={{ cursor: "pointer" }}
                      >
                        {showedPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                      </span>
                    }
                  />
                  // <OrtyInput
                  //   mb={1}
                  //   name="password"
                  //   props={{
                  //     placeholder: "Mot de passe",
                  //     type: showedPassword ? "password" : "text",
                  //     ...field,
                  //   }}
                  //   isError={fieldState?.error}
                  //   errorMessage={fieldState?.error?.message}
                  //   renderRightIcon={
                  //     <span
                  //       onClick={() => setshowedPassword(!showedPassword)}
                  //       style={{ cursor: "pointer" }}
                  //     >
                  //       {showedPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                  //     </span>
                  //   }
                  // />
                )}
              />
              <div style={{ float: "right" }}>
                <span className="passwoublier">Mot de passe oublié ?</span>
              </div>
              <div
                className="d-flex"
                style={{ justifyContent: "center", width: "100%" }}
              >
                <Button
                  onClick={handleSubmit(onSubmit)}
                  style={{
                    borderRadius: 30,
                    marginTop: 10,
                    fontSize: 15,
                    padding: "23px",
                  }}
                  size="default"
                  type="primary"
                >
                  {login_spinner ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    "Se connecter"
                  )}
                </Button>
                {/* <button
                  className="navbt orty-btn-orange"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  style={{
                    fontSize: 11,
                    alignSelf: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {login_spinner ? (
                    <Spinner size="sm" />
                  ) : (
                    <span>Se connecter</span>
                  )}
                </button> */}
              </div>
              <div className="d-flex mt-5" style={{ justifyContent: "center" }}>
                <div
                  style={{
                    fontSize: 12,
                    color: "rgba(58, 53, 65, 0.68)",
                    fontFamily: "Inter",
                    fontWeight: "400",
                  }}
                >
                  <span>Nouveau sur notre plateforme ?</span>
                  <span
                    onClick={() => {
                      navigate("/register");
                    }}
                    style={{
                      marginLeft: 8,
                      color: "#ec6855",
                      cursor: "pointer",
                      fontWeight: "600",
                    }}
                  >
                    Créer un compte
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
