// LandingScreen.js
import React from "react";
import CustomNavbar from "../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import HeroAnimation from "../components/LandingAnimation/HeroAnimation";
import FullDash from "../assets/landing/fulldash.png";
import { Button } from "../components/Button/Buttons";
import SectionServices from "../components/Sections/SectionServices";
import SectionPricing from "../components/Sections/SectionPricing";
import LastSection from "../components/Sections/LastSection";
const LandingScreen = () => {
  const navigate = useNavigate();
  return (
    <>
      <CustomNavbar />
      <main id="Hero" class="content-panel-hero background-gradient-light home">
        <div class="content flush-left hero">
          <div class="panel-text side-by-side">
            <h1 class="panel-heading center hero">
              Optimisez Votre Gestion avec Orty
            </h1>
            <p class="panel-paragraph">
              Découvrez Orty, votre solution tout-en-un pour une gestion
              efficace de votre e-commerce. Profitez d'une interface intuitive
              pour simplifier l'administration de vos stocks, utilisateurs, et
              plus encore.
            </p>
            <Button
              onClick={() => {
                navigate("/register");
              }}
              style={{
                borderRadius: 30,
                marginTop: 10,
                fontSize: 15,
                padding: "23px",
              }}
              size="default"
              type="primary"
            >
              Essai Gratuit
            </Button>
            {/* <a
              data-w-id="a7cac35f-1429-777b-af96-131c94642000"
              href="#"
              class="button w-button"
            >
              Learn more
            </a> */}
          </div>
          <div class="panel-visual-dashboard side-by-side">
            <div class="centeral-image">
              <img
                src={FullDash}
                loading="eager"
                width="500.5"
                data-w-id="5026e7ee-1962-0b49-9684-c03eda2fc6b0"
                alt="Image of Orty dashboard"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 500.5px, 100vw"
                class="hero-image"
              />
              <div class="html-embed w-embed w-iframe">
                <HeroAnimation />
              </div>
            </div>
          </div>
        </div>
      </main>
      <SectionServices />
      <SectionPricing />
      <LastSection />
    </>
  );
};

export default LandingScreen;

{
  /*
// <div>
    //   <CustomNavbar />
    //   <div className="container-px main-acceuil">
    //       <Section1
    //         t1={"BIENVENUE"}
    //         t2={"DANS NOTRE"}
    //         t3={"UNIVERS"}
    //         d1={"ORTY est la solution la plus pratique"}
    //         d2={"pour la gestion globale de votre"}
    //         d3={"boutique en ligne."}
    //       />
    //   </div>
    //   <div style={{ marginTop: 90 }}>
    //     <OrtyFramerAnimation>
    //       <Section2 />
    //     </OrtyFramerAnimation>
    //   </div>
    //   <div className="end-home-page" style={{ marginTop: 120 }}>
    //     <OrtyFramerAnimation>
    //       <Section3 />
    //     </OrtyFramerAnimation>
    //   </div>
    // </div>

*/
}
