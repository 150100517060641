import axios from "axios";
import { debugHttp } from "./debugHelper";
import { API_URL } from "./constants";

const api = axios.create();
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // alert('tryy')
        const rtoken = localStorage.getItem("refreshToken");
        const url = `${API_URL}/api/user/reauth`;
        const response = await axios.post(url, { refresh_token: rtoken });
        // debugHttp(url, response, false);
        const { access_token, refresh_token } = response.data;
        localStorage.setItem("token", access_token);
        localStorage.setItem("refreshToken", refresh_token);
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return axios(originalRequest);
      } catch (error) {
        // alert(error)
        // console.log("Force LogOut");
        console.error(error);
      }
    }
    return Promise.reject(error);
  }
);

export const get = async (url, config) => {
  const tokenValue = localStorage.getItem("token");
  try {
    let res = await api.get(url, {
      ...(config ?? {}),
      headers: { authorization: `Bearer ${tokenValue}` },
    });
    debugHttp(url, res);
    return res;
  } catch (error) {
    if (error.response?.status === 401) {
    //   alert("expire - get");
      //   store.dispatch({
      //     type: SHOW_TOAST_ON_EXPIRE,
      //     payload: true,
      //   });
    } else {
      throw error;
    }
  }
};

export const put = async (url, data, config) => {
  //   const state = store.getState();
  const tokenValue = localStorage.getItem("token");
  try {
    let res = await api.put(url, data, {
      ...(config ?? {}),
      headers: { Authorization: `Bearer ${tokenValue}` },
    });
    debugHttp(url, res);
    return res;
  } catch (error) {
    if (error.response?.status === 401) {
    //   alert("expire put");
      //   store.dispatch({
      //     type: SHOW_TOAST_ON_EXPIRE,
      //     payload: true,
      //   });
    } else {
      throw error;
    }
  }
};

export const patch = async (url, data, config) => {
  //   const state = store.getState();
  const tokenValue = localStorage.getItem("token");
  try {
    let res = await api.patch(url, data, {
      ...(config ?? {}),
      headers: { Authorization: `Bearer ${tokenValue}` },
    });
    debugHttp(url, res);
    return res;
  } catch (error) {
    if (error.response?.status === 401) {
      //   store.dispatch({
      //     type: SHOW_TOAST_ON_EXPIRE,
      //     payload: true,
      //   });
    } else {
      throw error;
    }
  }
};

export const putWFormData = async (url, data, config) => {
  // console.log("data img...");
  //   const state = store.getState();
  const tokenValue = localStorage.getItem("token");
  try {
    let res = await api.put(url, data, {
      ...(config ?? {}),
      headers: {
        Authorization: `Bearer ${tokenValue}`,
        "Content-Type": "multipart/form-data",
      },
    });
    debugHttp(url, res);
    return res;
  } catch (error) {
    // console.log(error?.response?.status);
    if (error.response?.status === 401) {
      //   store.dispatch({
      //     type: SHOW_TOAST_ON_EXPIRE,
      //     payload: true,
      //   });
    } else {
      throw error;
    }
  }
};

export const post = async (url, data, config) => {
  //   const state = store.getState();
  const tokenValue = localStorage.getItem("token");
  try {
    let res = await api.post(url, data, {
      ...(config ?? {}),
      headers: { Authorization: `Bearer ${tokenValue}` },
    });
    debugHttp(url, res);
    return res;
  } catch (error) {
    if (error.response?.status === 401) {
      //   store.dispatch({
      //     type: SHOW_TOAST_ON_EXPIRE,
      //     payload: true,
      //   });
    } else {
      throw error;
    }
  }
};


export const xdelete = async (url, config) => {
  const tokenValue = localStorage.getItem("token");
  try {
    let res = await api.delete(url, {
      ...(config ?? {}),
      headers: { authorization: `Bearer ${tokenValue}` },
    });
    debugHttp(url, res);
    return res;
  } catch (error) {
    if (error.response?.status === 401) {
    //   alert("expire - get");
      //   store.dispatch({
      //     type: SHOW_TOAST_ON_EXPIRE,
      //     payload: true,
      //   });
    } else {
      throw error;
    }
  }
};

export const anonymPost = async (url, data, config) => {
  let res = await axios.post(url, data, config);
  debugHttp(url, res);
  return res;
};

const httpClientHelper = {
  put,
  get,
  post,
  patch,
  xdelete,
  anonymPost,
  putWFormData,
};

export default httpClientHelper;
