import {
  CHANGE_COLIS_STATUS_SPINNER_DIS,
  CHANGE_COLIS_STATUS_SPINNER_EN,
  CHANGE_COLIS_STATUS_SUCCESS,
  GET_COLIS_SPINNER_DIS,
  GET_COLIS_SPINNER_EN,
  GET_COLIS_SUCCESS,
} from "../types";

const initialState = {
  get_colis_spinner: false,
  updateStatusLoader: false,
  colis: [],
};

export default function colisReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_COLIS_STATUS_SUCCESS:
      const { commandeIds } = action.payload;
      const updatedCs = state.colis.filter(
        (colis) => !commandeIds.includes(colis.id)
      );

      return {
        ...state,
        colis: updatedCs,
      };
    case CHANGE_COLIS_STATUS_SPINNER_EN:
      return {
        ...state,
        updateStatusLoader: true,
      };
    case CHANGE_COLIS_STATUS_SPINNER_DIS:
      return {
        ...state,
        updateStatusLoader: false,
      };

    case GET_COLIS_SPINNER_EN:
      return {
        ...state,
        get_colis_spinner: true,
      };
    case GET_COLIS_SPINNER_DIS:
      return {
        ...state,
        get_colis_spinner: false,
      };
    case GET_COLIS_SUCCESS:
      return {
        ...state,
        colis: action.payload,
      };
    default:
      return state;
  }
}
