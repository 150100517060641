import React from 'react';
import * as headings from './styles';

const Heading = props => {
  const { as, children, className, id } = props;
  const StyledHeading = as ? headings[as.toUpperCase()] : headings.H1;

  return (
    <StyledHeading className={className} id={id}>
      {children}
    </StyledHeading>
  );
};

Heading.defaultProps = {
  as: 'h1',
};

export default Heading;
