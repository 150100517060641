import { API_URL } from "../../utils/constants";
import httpClientHelper from "../../utils/httpClientHelper";
import {
  ADD_SWITCH_ID_TO_LOADER,
  ADD_USER_SPINNER_DIS,
  ADD_USER_SPINNER_EN,
  ADD_USER_SUCCESS,
  EDIT_PASSWORD_SPINNER_DIS,
  EDIT_PASSWORD_SPINNER_EN,
  EDIT_PROFILE_SPINNER_DIS,
  EDIT_PROFILE_SPINNER_EN,
  EDIT_PROFILE_SUCCESS,
  EDIT_USER_PASSWORD_SPINNER_DIS,
  EDIT_USER_PASSWORD_SPINNER_EN,
  GET_PROFILE_DETAILS_SPINNER_DIS,
  GET_PROFILE_DETAILS_SPINNER_EN,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_USERS_SPINNER_DIS,
  GET_USERS_SPINNER_EN,
  GET_USERS_SUCCESS,
  GET_USER_SPINNER_DIS,
  GET_USER_SPINNER_EN,
  GET_USER_SUCCESS,
  LOGIN_SPINNER_DIS,
  LOGIN_SPINNER_EN,
  LOGIN_SUCCESS,
  REGISTER_SPINNER_DIS,
  REGISTER_SPINNER_EN,
  REGISTER_SUCCESS,
  REMOVE_SWITCH_ID_FROM_LOADER,
  RESTORE_USER_SPINNER_DIS,
  RESTORE_USER_SPINNER_EN,
  RESTORE_USER_SUCCESS,
  SET_USER_PERMISSIONS,
  UPDATE_USER_SPINNER_DIS,
  UPDATE_USER_SPINNER_EN,
  USER_DELETE_SPINNER_DIS,
  USER_DELETE_SPINNER_EN,
  // UPDATE_USER_SUCCESS,
  USER_DELETE_SUCCESS,
} from "../types";
// import axios from "axios";
import { notification } from "antd";
export const loginAction = (body, navigate) => async (dispatch) => {
  dispatch({
    type: LOGIN_SPINNER_EN,
  });
  try {
    const res = await httpClientHelper.anonymPost(
      `${API_URL}/api/signin`,
      body
    );
    // console.log(res?.data);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user: res?.data?.data?.user,
        token: res?.data?.data?.access_token,
      },
    });
    // alert(JSON.stringify(res?.data));
    // console.log(res.data?.data?.user?.permissions);
    dispatch({
      type: SET_USER_PERMISSIONS,
      payload: res.data?.data?.user?.permissions,
    });
    localStorage.setItem("token", res?.data?.data?.access_token);
    localStorage.setItem("refreshToken", res?.data?.data?.refresh_token);
    localStorage.setItem("user", JSON.stringify(res?.data?.data?.user));
    navigate("/dashboard");
    dispatch({
      type: LOGIN_SPINNER_DIS,
    });
  } catch (err) {
    if (!err?.response) {
      notification.error({ message: "Aucune réponse du serveur" });
      dispatch({
        type: LOGIN_SPINNER_DIS,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: LOGIN_SPINNER_DIS,
      });
    } else if (err.response?.status === 500) {
      dispatch({
        type: LOGIN_SPINNER_DIS,
      });
      notification.error({ message: "Erreur interne du serveur" });
    }
  }
};

export const registerOwnerAction = (body, navigate) => async (dispatch) => {
  dispatch({
    type: REGISTER_SPINNER_EN,
  });
  try {
    const res = await httpClientHelper.anonymPost(
      `${API_URL}/api/signup`,
      body
    );
    // console.log(res?.data);
    // console.log("token",res?.headers["X-Auth"])
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res?.data,
    });

    navigate("/login");
    dispatch({
      type: REGISTER_SPINNER_DIS,
    });
  } catch (err) {
    if (!err?.response) {
      notification.error({ message: "Aucune réponse du serveur" });
      dispatch({
        type: REGISTER_SPINNER_DIS,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: REGISTER_SPINNER_DIS,
      });
    } else if (err.response?.status === 401) {
      dispatch({
        type: REGISTER_SPINNER_DIS,
      });
      notification.error({ message: "Invalid credentials" });
    } else if (err.response?.status === 500) {
      dispatch({
        type: REGISTER_SPINNER_DIS,
      });
      notification.error({ message: err?.response?.data?.message });
    }
  }
};

export const createUser = (body, navigate) => async (dispatch) => {
  dispatch({
    type: ADD_USER_SPINNER_EN,
  });
  try {
    const res = await httpClientHelper.post(`${API_URL}/api/createUser`, body);
    dispatch({
      type: ADD_USER_SUCCESS,
      payload: res?.data?.data,
    });
    notification.success({ message: "L'utilisateur créer avec succées." });
    navigate("/equipe");
    dispatch({
      type: ADD_USER_SPINNER_DIS,
    });
  } catch (err) {
    if (!err?.response) {
      notification.error({ message: "Impossible de créer un utilisateurs" });
      dispatch({
        type: ADD_USER_SPINNER_DIS,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: ADD_USER_SPINNER_DIS,
      });
    } else if (err.response?.status === 401) {
      dispatch({
        type: ADD_USER_SPINNER_DIS,
      });
      notification.error({ message: "Impossible de créer un utilisateurs" });
    } else if (err.response?.status === 500) {
      dispatch({
        type: ADD_USER_SPINNER_DIS,
      });
      notification.error({ message: "Impossible de créer un utilisateurs" });
    }
  }
};

export const getUsers =
  (search = "") =>
  async (dispatch) => {
    dispatch({
      type: GET_USERS_SPINNER_EN,
    });
    try {
      const res = await httpClientHelper.get(
        `${API_URL}/api/users?${search === "" ? "" : `search=${search}`}`
      );
      // console.log(res?.data);
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: res?.data.data,
      });
      dispatch({
        type: GET_USERS_SPINNER_DIS,
      });
    } catch (err) {
      if (!err?.response) {
        notification.error({
          message: "Impossible de récupérer les utilisateurs",
        });
        dispatch({
          type: GET_USERS_SPINNER_DIS,
        });
      } else if (err.response?.status === 400) {
        notification.error({ message: err?.response?.data?.message });
        dispatch({
          type: GET_USERS_SPINNER_DIS,
        });
      } else if (err.response?.status === 401) {
        dispatch({
          type: GET_USERS_SPINNER_DIS,
        });
        notification.error({
          message: "Impossible de récupérer les utilisateurs",
        });
      } else if (err.response?.status === 500) {
        dispatch({
          type: GET_USERS_SPINNER_DIS,
        });
        notification.error({
          message: "Impossible de récupérer les utilisateurs",
        });
      }
    }
  };

export const getUser = (id) => async (dispatch) => {
  dispatch({
    type: GET_USER_SPINNER_EN,
  });
  try {
    const res = await httpClientHelper.get(`${API_URL}/api/user/${id}`);
    // console.log(res?.data);
    dispatch({
      type: GET_USER_SUCCESS,
      payload: res?.data.data,
    });
    dispatch({
      type: GET_USER_SPINNER_DIS,
    });
  } catch (err) {
    if (!err?.response) {
      notification.error({ message: "Impossible de récupérer l'utilisateur" });
      dispatch({
        type: GET_USER_SPINNER_DIS,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: GET_USER_SPINNER_DIS,
      });
    } else if (err.response?.status === 401) {
      dispatch({
        type: GET_USER_SPINNER_DIS,
      });
      notification.error({ message: "Impossible de récupérer l'utilisateur" });
    } else if (err.response?.status === 500) {
      dispatch({
        type: GET_USER_SPINNER_DIS,
      });
      notification.error({ message: "Impossible de récupérer l'utilisateur" });
    }
  }
};

export const getSelfUser = () => async (dispatch) => {
  dispatch({
    type: GET_PROFILE_DETAILS_SPINNER_EN,
  });
  try {
    const res = await httpClientHelper.get(`${API_URL}/api/user`);
    // console.log(res?.data);
    dispatch({
      type: GET_PROFILE_DETAILS_SUCCESS,
      payload: res?.data.data?.user,
    });
    dispatch({
      type: GET_PROFILE_DETAILS_SPINNER_DIS,
    });
  } catch (err) {
    if (!err?.response) {
      notification.error({
        message: "Impossible de récupérer vos informations",
      });
      dispatch({
        type: GET_PROFILE_DETAILS_SPINNER_DIS,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: GET_PROFILE_DETAILS_SPINNER_DIS,
      });
    } else if (err.response?.status === 401) {
      dispatch({
        type: GET_PROFILE_DETAILS_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de récupérer vos informations",
      });
    } else if (err.response?.status === 500) {
      dispatch({
        type: GET_PROFILE_DETAILS_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de récupérer vos informations",
      });
    }
  }
};

export const updateUser = (id, body, navigate) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_SPINNER_EN,
  });
  try {
    await httpClientHelper.put(`${API_URL}/api/user/${id}`, body);
    // dispatch({
    //   type: UPDATE_USER_SUCCESS,
    //   payload: {
    //     user: res?.data.data,
    //     userId: id,
    //   },
    // });
    dispatch({
      type: UPDATE_USER_SPINNER_DIS,
    });
    navigate("/equipe");
  } catch (err) {
    // console.log(err);
    if (!err?.response) {
      notification.error({
        message: "Impossible de mettre a jour l'utilisateur",
      });
      dispatch({
        type: UPDATE_USER_SPINNER_DIS,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: UPDATE_USER_SPINNER_DIS,
      });
    } else if (err.response?.status === 401) {
      dispatch({
        type: UPDATE_USER_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de mettre a jour l'utilisateur",
      });
    } else if (err.response?.status === 500) {
      dispatch({
        type: UPDATE_USER_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de mettre a jour l'utilisateur",
      });
    }
  }
};

export const deleteUser = (id) => async (dispatch) => {
  dispatch({
    type: ADD_SWITCH_ID_TO_LOADER,
    id,
  });
  dispatch({
    type: USER_DELETE_SPINNER_EN,
  });
  try {
    const res = await httpClientHelper.xdelete(`${API_URL}/api/user/${id}`);
    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: {
        usrId: id,
        uxr: res?.data?.data,
      },
    });
    notification.success({ message: "L'utilisateur inactive." });
    dispatch({
      type: USER_DELETE_SPINNER_DIS,
    });
    dispatch({
      type: REMOVE_SWITCH_ID_FROM_LOADER,
      id,
    });
  } catch (err) {
    // console.log(err);
    // alert(err?.response)
    if (!err?.response) {
      notification.error({
        message: "Impossible de mettre a jour l'utilisateur",
      });
      dispatch({
        type: USER_DELETE_SPINNER_DIS,
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: USER_DELETE_SPINNER_DIS,
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
    } else if (err.response?.status === 401) {
      dispatch({
        type: USER_DELETE_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de mettre a jour l'utilisateur",
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
    } else if (err.response?.status === 500) {
      dispatch({
        type: USER_DELETE_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de mettre a jour l'utilisateur",
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
    }
  }
};

export const restoreUser = (id) => async (dispatch) => {
  dispatch({
    type: ADD_SWITCH_ID_TO_LOADER,
    id,
  });
  dispatch({
    type: RESTORE_USER_SPINNER_EN,
  });
  try {
    const res = await httpClientHelper.get(`${API_URL}/api/restoreUser/${id}`);
    dispatch({
      type: RESTORE_USER_SUCCESS,
      payload: {
        uid: id,
        ur: res?.data?.data,
      },
    });
    notification.success({ message: "L'utilisateur active." });
    dispatch({
      type: RESTORE_USER_SPINNER_DIS,
    });
    dispatch({
      type: REMOVE_SWITCH_ID_FROM_LOADER,
      id,
    });
  } catch (err) {
    // console.log(err);
    // alert(err?.response)
    if (!err?.response) {
      notification.error({
        message: "Impossible de mettre a jour l'utilisateur",
      });
      dispatch({
        type: RESTORE_USER_SPINNER_DIS,
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: RESTORE_USER_SPINNER_DIS,
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
    } else if (err.response?.status === 401) {
      dispatch({
        type: RESTORE_USER_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de mettre a jour l'utilisateur",
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
    } else if (err.response?.status === 500) {
      dispatch({
        type: RESTORE_USER_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de mettre a jour l'utilisateur",
      });
      dispatch({
        type: REMOVE_SWITCH_ID_FROM_LOADER,
        id,
      });
    }
  }
};

export const updateSelfUser = (body) => async (dispatch) => {
  dispatch({
    type: EDIT_PROFILE_SPINNER_EN,
  });
  try {
    const res = await httpClientHelper.put(`${API_URL}/api/user`, body);
    dispatch({
      type: EDIT_PROFILE_SUCCESS,
      payload: res?.data?.data,
    });
    dispatch({
      type: EDIT_PROFILE_SPINNER_DIS,
    });
    notification.success({ message: "votre profile mis a jour avec succées." });
  } catch (err) {
    // console.log(err);
    if (!err?.response) {
      notification.error({
        message: "Impossible de mettre a jour votre profile",
      });
      dispatch({
        type: EDIT_PROFILE_SPINNER_DIS,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: EDIT_PROFILE_SPINNER_DIS,
      });
    } else if (err.response?.status === 401) {
      dispatch({
        type: EDIT_PROFILE_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de mettre a jour votre profile",
      });
    } else if (err.response?.status === 500) {
      dispatch({
        type: EDIT_PROFILE_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de mettre a jour votre profile",
      });
    }
  }
};

export const updateSelfPassword = (body) => async (dispatch) => {
  dispatch({
    type: EDIT_PASSWORD_SPINNER_EN,
  });
  try {
    await httpClientHelper.put(`${API_URL}/api/change-paasword`, body);
    dispatch({
      type: EDIT_PASSWORD_SPINNER_DIS,
    });
    notification.success({
      message: "votre mot de passe mis a jour avec succées.",
    });
  } catch (err) {
    // console.log(err);
    if (!err?.response) {
      notification.error({
        message: "Impossible de mettre a jour votre mot de passe",
      });
      dispatch({
        type: EDIT_PASSWORD_SPINNER_DIS,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: EDIT_PASSWORD_SPINNER_DIS,
      });
    } else if (err.response?.status === 401) {
      dispatch({
        type: EDIT_PASSWORD_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de mettre a jour votre mot de passe",
      });
    } else if (err.response?.status === 500) {
      dispatch({
        type: EDIT_PASSWORD_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de mettre a jour votre mot de passe",
      });
    }
  }
};

export const updateUserPassword = (id, body, reset) => async (dispatch) => {
  dispatch({
    type: EDIT_USER_PASSWORD_SPINNER_EN,
  });
  try {
    await httpClientHelper.put(
      `${API_URL}/api/user-change-paasword/${id}`,
      body
    );
    dispatch({
      type: EDIT_USER_PASSWORD_SPINNER_DIS,
    });
    reset();
    notification.success({
      message: "le mot de passe mis a jour avec succées.",
    });
  } catch (err) {
    // console.log(err);
    if (!err?.response) {
      notification.error({
        message: "Impossible de mettre a jour le mot de passe",
      });
      dispatch({
        type: EDIT_USER_PASSWORD_SPINNER_DIS,
      });
    } else if (err.response?.status === 400) {
      notification.error({ message: err?.response?.data?.message });
      dispatch({
        type: EDIT_USER_PASSWORD_SPINNER_DIS,
      });
    } else if (err.response?.status === 401) {
      dispatch({
        type: EDIT_USER_PASSWORD_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de mettre a jour le mot de passe",
      });
    } else if (err.response?.status === 500) {
      dispatch({
        type: EDIT_USER_PASSWORD_SPINNER_DIS,
      });
      notification.error({
        message: "Impossible de mettre a jour le mot de passe",
      });
    }
  }
};
