import {
  ADD_CHARGE_LOADER_DIS,
  ADD_CHARGE_LOADER_EN,
  ADD_CHARGE_SUCCESS,
  GET_CHARGES_LOADER_DIS,
  GET_CHARGES_LOADER_EN,
  GET_CHARGES_SUCCESS,
  UPDATE_CHARGE_SUCCESS,
} from "../types";

const initialState = {
  charges: [],
  add_charges_spinner: false,
  get_charges_spinner: false,
};

export default function chargesReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CHARGE_SUCCESS:
  const { achat, chargeId } = action.payload;
  const updatedCharges = state.charges.map(charge =>
    charge.id === chargeId ? achat : charge
  );
  return {
    ...state,
    charges: updatedCharges,
  };
    case GET_CHARGES_LOADER_EN:
      return {
        ...state,
        get_charges_spinner: true,
      };
    case GET_CHARGES_LOADER_DIS:
      return {
        ...state,
        get_charges_spinner: false,
      };
    case GET_CHARGES_SUCCESS:
      return {
        ...state,
        charges: action.payload,
      };
    case ADD_CHARGE_LOADER_EN:
      return {
        ...state,
        add_charges_spinner: true,
      };
    case ADD_CHARGE_LOADER_DIS:
      return {
        ...state,
        add_charges_spinner: false,
      };
    case ADD_CHARGE_SUCCESS:
      return {
        ...state,
        charges: [...state.charges, action.payload],
      };
    default:
      return state;
  }
}
