import React, { Fragment } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { Spinner } from "react-bootstrap";
const OrtyInput = ({
  renderRightIcon,
  props,
  style = {},
  name,
  isError,
  errorMessage,
  mb = 3,
  isLoading = false,
  isValidInput = null,
  disabled = false,
  type = "text",
  height = null,
  ps = false,
}) => {
  return (
    <Fragment>
      <InputGroup style={style} className={isError ? "" : `mb-${mb}`}>
        <div style={{ position: "relative" }}></div>
        <Form.Control
          disabled={disabled}
          className="orty-input-px"
          style={
            isError || isValidInput === false
              ? {
                  borderColor: "#f5222d",
                  borderWidth: 1,
                }
              : isValidInput === true
              ? {
                  borderColor: "green",
                  borderWidth: 1,
                }
              : height !== null
              ? {
                  height: height,
                }
              : {}
          }
          name={name}
          type={type}
          {...props}
        ></Form.Control>
        <div
          className="orty-inner"
          style={{
            position: "absolute",
            right: "10px",
            alignItems: "center",
            bottom: ps ? "23.5px" : "22.5px",
            zIndex: 99,
          }}
        >
          {isLoading === true && <Spinner color="black" size="sm" />}
          {renderRightIcon}
        </div>
      </InputGroup>
      {isError ? (
        <p
          className="fade-in-up"
          style={{
            color: "#f5222d",
            fontSize: 12,
            fontFamily: "Inter",
            fontWeight: "400",
          }}
        >
          {errorMessage}
        </p>
      ) : isValidInput === false ? (
        <p
          className="fade-in-up"
          style={{
            color: "#f5365c",
            fontSize: 12,
            fontFamily: "Inter",
            fontWeight: "400",
          }}
        >
          Reference Non disponible
        </p>
      ) : isValidInput === true ? (
        <p
          className="fade-in-up"
          style={{
            color: "green",
            fontSize: 12,
            fontFamily: "Inter",
            fontWeight: "400",
          }}
        >
          Reference disponible
        </p>
      ) : null}
    </Fragment>
  );
};

export default OrtyInput;
