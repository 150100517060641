// import logo from "./logo.svg";
// import { Suspense, lazy } from "react";
import "./App.css";
import { hot } from "react-hot-loader/root";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingScreen from "./screens/Landing";
// import Colis from "./screens/Colis";
// import Stock from "./screens/Stock";
// import SocieteLivraison from "./screens/SocieteLivraison";
// import Sponsoring from "./screens/Sponsoring";
// import Fournisseur from "./screens/Fournisseur";
// import StatistiqueGain from "./screens/StatistiqueGain";
// import Equipe from "./screens/Equipe";
// import Boutique from "./screens/Boutique";
// import Offres from "./screens/Offres";
// // import Dashboard from "./screens/Dashboard";
// import Achats from "./screens/Achat";
// import PaymentEffectuer from "./screens/PaymentEffectuer";
// import PaymentRecu from "./screens/PaymentRecu";
import Login from "./screens/Login";
import Register from "./screens/Register";
import { ToastContainer } from "react-toastify";
import { NotAuthRoute, ProtectedRoute } from "./utils/ProtectedRoute";
import AdminRoutes from "./routes/adminRoutes";
// import Page404 from "./screens/Page404";
// import AddProduct from "./screens/Stock/Product/AddProduct";
// import EditProduct from "./screens/Stock/Product/EditProduct";
// import AddCommande from "./screens/Commande/AddCommande";
// // import CommandeClient from "./screens/Commande/CommandeClient";
// import EditCommande from "./screens/Commande/EditCommande";
// import ClientExtraListing from "./screens/ClientExtra/ClientExtraListing";
// import AddCommandeClientExtra from "./screens/ClientExtra/AddCommandeClientExtra";
// import CommandeClientExtra from "./screens/ClientExtra/CommandeClientExtra";
// import CreateUser from "./screens/User/CreateUser/CreateUser";
// import {
//   ARRIVAGE_CREATE_PERM,
//   BONSORTIE_PERM_NAME,
//   BOUTIQUE_PERM_NAME,
//   CHARGE_PERM_NAME,
//   CLIENT_PERM_NAME,
//   CLIENT_SUPPLIMENTAIRE_COMMANDE_PER_NAME,
//   CLIENT_SUPPLIMENTAIRE_LIST_PER_NAME,
//   COLIS_PERM_NAME,
//   COMMANDE_PERM_NAME,
//   CREATE_PERM,
//   DASHBOARD_PERM_NAME,
//   DELIVERY_PERM_NAME,
//   EDIT_PERM,
//   EQUIPE_PERM_NAME,
//   FETCH_PERM,
//   FOURNISSEUR_PERM_NAME,
//   OFFRE_PERM_NAME,
//   PAIEMENT_EFFECTED_PER_NAME,
//   PAIEMENT_RECU_PERM_NAME,
//   SPONSORING_PERM_NAME,
//   STATISTIC_PERM_NAME,
//   STOCK_PERM_NAME,
// } from "./utils/permissionsCommun";
// import EditUser from "./screens/User/EditUser/EditUser";
// import BonSortie from "./screens/BonSortie/BonSortie";
// import ProductArrivage from "./screens/Stock/Arrivage/ProductArrivage";
// import { Spin } from "antd";
// import ThemedProtectedRoute from "./layout/ThemedProtectedRoutes";
// const Dashboard = lazy(() => import("./screens/Dashboard"));
// const CommandeClient = lazy(() => import("./screens/Commande/CommandeClient"));
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <Suspense
          fallback={
            <div className="spin">
              <Spin />
            </div>
          }
        > */}
        <ToastContainer />
        <Routes>
          {/* <Route path="/" element={<LandingScreen />} /> */}
          <Route element={<NotAuthRoute />}>
            <Route path="/" element={<LandingScreen />} />
          </Route>
          <Route element={<NotAuthRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route element={<NotAuthRoute />}>
            <Route path="/register" element={<Register />} />
          </Route>
          <Route path="/*" element={<AdminRoutes />} />
        </Routes>
        {/* </Suspense> */}
      </BrowserRouter>
    </div>
  );
}

export default hot(App);
