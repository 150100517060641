import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { hasPermission } from "./commun";
import { useSelector } from "react-redux";

export const ProtectedRoute = ({ name, perm }) => {
  const { mine_permissions } = useSelector((state) => state.user);
  const { token, user } = useSelector((state) => state.user);
  // const token = localStorage.getItem("token");
  // alert(JSON.stringify(user));
  const location = useLocation();
  const isAdmin = user?.role;
  return (
    <>
      {token && (isAdmin === "ADMIN" || hasPermission(mine_permissions, name, perm)) ? (
        <Outlet />
      ) : !token ? (
        <Navigate to="/" state={{ from: location }} replace />
      ) : (
        <Navigate to="/dashboard" state={{ from: location }} replace />
      )}
    </>
  );
};

export const NotAuthRoute = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  return (
    <>
      {token ? (
        <Navigate to="/dashboard" state={{ from: location }} replace />
      ) : (
        <Outlet />
      )}
    </>
  );
};
