export const sidebarRoutes = [
  {
    path: "/dashboard",
    title: "Dashboard",
  },
  {
    path: "/commande-client",
    title: "Commande client",
    permission: "fetch",
  },
  {
    path: "/colis",
    title: "Colis",
  },
  {
    path: "/stock",
    title: "Stock",
  },
  {
    path: "/bon-de-sortie",
    title: "Bon des sorties",
  },
  {
    path: "/societes-de-livraison",
    title: "Société de livraison",
  },
  {
    // path: '/Charge',
    title: "Achat",
    hasChild: true,
    items: [
      {
        path: "/achat",
        // icon: Charge />),
        title: "Charge",
      },

      {
        path: "/sponsoring",
        // icon: Charge />),
        title: "Sponsoring",
      },
    ],
  },
  {
    // path: '/paiement',
    title: "Paiement",
    hasChild: true,
    items: [
      {
        path: "/paiement-effectues",
        // icon: Charge />),
        title: "Paiement effectués",
      },

      {
        path: "/paiement-recus",
        // icon: Charge />),
        title: "Paiement reçus",
      },
    ],
  },
  {
    path: "/fournisseur",
    title: "Fournisseur",
  },
  {
    // path: "/extra-clients",
    title: "Clients extra",
    hasChild: true,
    items: [
      {
        path: "/extra-clients",
        // icon: Charge />),
        title: "Clients extra",
      },
      {
        path: "/clientExtra-commandes",
        // icon: Charge />),
        title: "Commandes extra",
      },
    ],
  },
  {
    path: "/statistics-earnings",
    title: "Statistique et gain",
  },
  {
    path: "/boutiques",
    title: "Vos boutiques",
  },
  {
    path: "/equipe",
    title: "Gestion d'équipe",
  },
  {
    path: "/offres",
    title: "Nos offres",
  },
];
