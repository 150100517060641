// import React from "react";
// import { Row, Col } from "antd";
// import { Badge, ListGroup, PricingCard } from "../../screens/commonStyle";
// import Heading from "../heading/HeadingText";
// import { Button } from "../Button/Buttons";
// import { List } from "../OffreCard/List";
// const SectionPricing = () => {
//   return (
//     <section class="content-panel-factoids background-purple100 no-min-height">
//       <div class="content flush-left w-container">
//         <div class="panel-text" style={{ marginBottom: 50 }}>
//           <h2 class="panel-heading">Tarifs Adaptés à Vos Besoins</h2>
//           <p class="panel-paragraph center">
//             Explorez nos packs conçus pour répondre à toutes vos exigences.
//             Choisissez le plan qui vous convient pour bénéficier de
//             fonctionnalités adaptées à votre entreprise.{" "}
//           </p>
//         </div>
//         <Row gutter={32} justify="center">
//           <Col xxl={6} lg={8} sm={12} xs={24}>
//             <PricingCard style={{ marginBottom: 30 }}>
//               <Badge className="pricing-badge" type="dark">
//                 Gratuit
//               </Badge>
//               <Heading className="pricing-title" as="h3">
//                 Gratuit
//               </Heading>
//               <span className="package-user-type">Pour les particuliers.</span>
//               <ListGroup>
//                 <List text="Orders illimitées." />
//                 <List text="Gestion de societées" />
//                 <List text="Gestion de Commande avancées." />
//               </ListGroup>
//               <Button size="default" type="white">
//                 Plan Actuel
//               </Button>
//             </PricingCard>
//           </Col>
//           <Col xxl={6} lg={8} sm={12} xs={24}>
//             <PricingCard style={{ marginBottom: 30 }}>
//               <Badge className="pricing-badge" type="primary">
//                 Plan de Base
//               </Badge>
//               <Heading className="price-amount" as="h3">
//                 300 <sup className="currency">TND</sup>
//                 <sub className="pricing-validity">Par ans</sub>
//               </Heading>
//               <span className="package-user-type">Pour 2 Vendeurs</span>
//               <ListGroup>
//                 <List text="Orders illimitées." />
//                 <List text="Gestion de societées" />
//                 <List text="Gestion de Commande avancées." />
//               </ListGroup>
//               <Button size="default" type="primary">
//                 Commencer
//               </Button>
//             </PricingCard>
//           </Col>
//           <Col xxl={6} lg={8} sm={12} xs={24}>
//             <PricingCard style={{ marginBottom: 30 }}>
//               <Badge className="pricing-badge" type="secondary">
//                 Entreprise
//               </Badge>
//               <Heading className="price-amount" as="h3">
//                 599 <sup className="currency">TND</sup>
//                 <sub className="pricing-validity">Par ans</sub>
//               </Heading>
//               <span className="package-user-type">Pour 6 Vendeurs</span>
//               <ListGroup>
//                 <List text="Orders illimitées." />
//                 <List text="Gestion de societées" />
//                 <List text="Gestion de Commande avancées." />
//               </ListGroup>
//               <Button size="default" type="secondary">
//                 Commencer
//               </Button>
//             </PricingCard>
//           </Col>
//         </Row>
//       </div>
//     </section>
//   );
// };

// export default SectionPricing;

import React from "react";
import { Row, Col } from "antd";
import { Badge, ListGroup, PricingCard } from "../../screens/commonStyle";
import Heading from "../heading/HeadingText";
import { Button } from "../Button/Buttons";
import { List } from "../OffreCard/List";
import { OrtyCheck } from "../../utils/icons";
const SectionPricing = () => {
  const pricing = [
    {
      name: "Gratuit",
      description:
        "Testez Orty gratuitement avec notre pack de base. Idéal pour découvrir les fonctionnalités essentielles sans engagement.",
      price: "Gratuit",
      per: "Par ans",
      whatgot: [
        { has: "Accès limité aux fonctionnalités principales" },
        { has: "Support de base" },
        { has: "Nombre restreint d’utilisateurs" },
      ],
    },
    {
      name: "Standard",
      description:
        "Passez au niveau supérieur avec plus de fonctionnalités pour une gestion optimisée. Parfait pour les entreprises en croissance.",
      price: "299 TND",
      per: "Par ans",
      whatgot: [
        { has: "Accès complet aux fonctionnalités avancées" },
        { has: "Support prioritaire" },
        { has: "Nombre d’utilisateurs illimité" },
        { has: "Rapports détaillés" },
      ],
    },
    {
      name: "Premium",
      description:
        "Bénéficiez de la solution la plus complète avec des fonctionnalités supplémentaires et un support dédié. Idéal pour les grandes entreprises.",
      price: "499 TND",
      per: "Par ans",
      whatgot: [
        { has: "Toutes les fonctionnalités du Pack Standard" },
        { has: "Support dédié et personnalisé" },
        { has: "Intégrations avancées" },
        { has: "Analyse et prévisions avancées" },
      ],
    },
  ];
  return (
    <section class="grid-2-col careers-hero purple-100 pricing">
      <div class="content pricing w-container">
        <div class="panel-text centered">
          <h2 class="panel-heading">Tarifs Adaptés à Vos Besoins</h2>
          <p class="panel-paragraph center">
            Explorez nos packs conçus pour répondre à toutes vos exigences.
            Choisissez le plan qui vous convient pour bénéficier de
            fonctionnalités adaptées à votre entreprise.
          </p>
        </div>
        <div class="pricing-packages">
          {pricing?.map((item, index) => (
            <div key={index?.toString()} class="pricing-package">
              <div class="package-top">
                <div class="top-a">
                  <h4 class="package-title">{item?.name}</h4>
                  <div class="package-description">{item?.description}</div>
                  <div>A partir de</div>
                </div>
                <div class="top-b">
                  <div class="div-block-14">
                    <div class="package-price">
                      {item?.price}
                      <strong>
                        <sup>*</sup>
                      </strong>
                    </div>
                    <div class="package-permonth">*{item?.per}</div>
                  </div>
                  <a
                    data-w-id="4d765167-86f8-03f5-0fca-ee548d44f970"
                    href="#"
                    class="button button-outline w-button"
                  >
                    Commencer
                  </a>
                </div>
              </div>
              <div class="package-includes">
                <div class="text-block-16">
                  Le forfait <strong>Essential</strong> comprend:
                </div>
                <div class="pricing-bulletpoints">
                  {item?.whatgot?.map((item, index) => (
                    <div
                      key={`wg-${index?.toString()}`}
                      class="pricing-bulletpoint"
                    >
                      <div class="checkmark">
                        <OrtyCheck />
                      </div>
                      <p class="pricing-bullet-text">{item?.has}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SectionPricing;
