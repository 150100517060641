import {
  ADD_SWITCH_ID_TO_LOADER,
  ADD_USER_SPINNER_DIS,
  ADD_USER_SPINNER_EN,
  EDIT_PASSWORD_SPINNER_DIS,
  EDIT_PASSWORD_SPINNER_EN,
  EDIT_PROFILE_SPINNER_DIS,
  EDIT_PROFILE_SPINNER_EN,
  EDIT_PROFILE_SUCCESS,
  EDIT_USER_PASSWORD_SPINNER_DIS,
  EDIT_USER_PASSWORD_SPINNER_EN,
  GET_PROFILE_DETAILS_SPINNER_DIS,
  GET_PROFILE_DETAILS_SPINNER_EN,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_USERS_SPINNER_DIS,
  GET_USERS_SPINNER_EN,
  GET_USERS_SUCCESS,
  LOGIN_SPINNER_DIS,
  LOGIN_SPINNER_EN,
  LOGIN_SUCCESS,
  REGISTER_SPINNER_DIS,
  REGISTER_SPINNER_EN,
  REMOVE_SWITCH_ID_FROM_LOADER,
  RESET_USER_DETAILS,
  RESTORE_USER_SPINNER_DIS,
  RESTORE_USER_SPINNER_EN,
  RESTORE_USER_SUCCESS,
  SET_USER_PERMISSIONS,
  UPDATE_PERMISSION_ON_USER_DETAILS,
  UPDATE_USER_SPINNER_DIS,
  UPDATE_USER_SPINNER_EN,
  UPDATE_USER_SUCCESS,
  USER_DELETE_SPINNER_DIS,
  USER_DELETE_SPINNER_EN,
  USER_DELETE_SUCCESS,
} from "../types";

const initialState = {
  login_spinner: false,
  register_spinner: false,
  mine_permissions: [],
  edit_user_loader: false,
  user: null,
  token: null,
  users: [],
  users_spinner: false,
  add_user_spinner: false,
  delete_user_spinner: false,
  switch_userIds: [],
  self_loader: false,
  self_profile: null,
  edit_profile_loader: false,
  edit_password_loader: false,
  edit_user_password_loader: false,
};

export default function userReducer(state = initialState, action) {
  // alert(action.type)
  switch (action.type) {
    case EDIT_USER_PASSWORD_SPINNER_EN:
      return {
        ...state,
        edit_user_password_loader: true,
      };
    case EDIT_USER_PASSWORD_SPINNER_DIS:
      return {
        ...state,
        edit_user_password_loader: false,
      };
    case EDIT_PASSWORD_SPINNER_EN:
      return {
        ...state,
        edit_password_loader: true,
      };
    case EDIT_PASSWORD_SPINNER_DIS:
      return {
        ...state,
        edit_password_loader: false,
      };
    case EDIT_PROFILE_SPINNER_EN:
      return {
        ...state,
        edit_profile_loader: true,
      };
    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        self_profile: action.payload,
      };
    case EDIT_PROFILE_SPINNER_DIS:
      return {
        ...state,
        edit_profile_loader: false,
      };
    case GET_PROFILE_DETAILS_SPINNER_EN:
      return {
        ...state,
        self_loader: true,
      };
    case GET_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        self_profile: action.payload,
      };
    case GET_PROFILE_DETAILS_SPINNER_DIS:
      return {
        ...state,
        self_loader: false,
      };

    case USER_DELETE_SPINNER_EN:
      return {
        ...state,
        delete_user_spinner: true,
      };
    case USER_DELETE_SPINNER_DIS:
      return {
        ...state,
        delete_user_spinner: false,
      };
    case RESTORE_USER_SPINNER_EN:
      return {
        ...state,
        delete_user_spinner: true,
      };
    case RESTORE_USER_SPINNER_DIS:
      return {
        ...state,
        delete_user_spinner: false,
      };
    case ADD_SWITCH_ID_TO_LOADER:
      if (!state.switch_userIds.includes(action.id)) {
        return {
          ...state,
          switch_userIds: [...state.switch_userIds, action.id],
        };
      }
      return state;

    case REMOVE_SWITCH_ID_FROM_LOADER:
      return {
        ...state,
        switch_userIds: state.switch_userIds.filter((id) => id !== action.id),
      };

    case UPDATE_PERMISSION_ON_USER_DETAILS:
      // const { index, permFunc, isChecked } = action.payload;
      // const updatedPermissions = [...state.userDetails.permissions];
      // const item = updatedPermissions[index];

      // const existingItemIndex = updatedPermissions.findIndex(
      //   (i) => i.name === item.name
      // );

      // if (existingItemIndex === -1) {
      //   updatedPermissions.push({
      //     name: item.name,
      //     permissions: isChecked
      //       ? [permFunc, permFunc !== "FETCH" ? "FETCH" : null].filter(Boolean)
      //       : [],
      //   });
      // } else {
      //   const permissions = updatedPermissions[existingItemIndex].permissions;
      //   if (isChecked) {
      //     if (!permissions.includes(permFunc)) {
      //       permissions.push(permFunc);
      //     }
      //     if (permFunc !== "FETCH" && !permissions.includes("FETCH")) {
      //       permissions.push("FETCH");
      //     }
      //   } else {
      //     const permIndex = permissions.indexOf(permFunc);
      //     if (permIndex !== -1) {
      //       permissions.splice(permIndex, 1);
      //     }
      //     if (permFunc === "FETCH") {
      //       updatedPermissions[existingItemIndex].permissions =
      //         updatedPermissions[existingItemIndex].permissions.filter(
      //           (perm) => perm === "FETCH"
      //         );
      //     }
      //   }
      // }

      return {
        ...state,
        // userDetails: {
        // ...state.userDetails,
        // permissions: updatedPermissions,
        // },
      };
    case USER_DELETE_SUCCESS:
      const { usrId, uxr } = action.payload;
      const updateAfterDelete = state.users.map((u) =>
        u.id === usrId ? uxr : u
      );
      return {
        ...state,
        users: updateAfterDelete,
      };
    case RESTORE_USER_SUCCESS:
      const { uid, ur } = action.payload;
      const updateAfterRestore = state.users.map((u) =>
        u.id === uid ? ur : u
      );
      return {
        ...state,
        users: updateAfterRestore,
      };

    case RESET_USER_DETAILS:
      return {
        ...state,
        // userDetails: null,
      };
    case UPDATE_USER_SPINNER_EN:
      return {
        ...state,
        edit_user_loader: true,
      };
    case UPDATE_USER_SPINNER_DIS:
      return {
        ...state,
        edit_user_loader: false,
      };
    case UPDATE_USER_SUCCESS:
      const { user, userId } = action.payload;
      const updatedUser = state.users.map((u) => (u.id === userId ? user : u));
      return {
        ...state,
        users: updatedUser,
      };
    case SET_USER_PERMISSIONS:
      return {
        ...state,
        mine_permissions: action.payload,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };
    case GET_USERS_SPINNER_EN:
      return {
        ...state,
        users_spinner: true,
      };
    case GET_USERS_SPINNER_DIS:
      return {
        ...state,
        users_spinner: false,
      };
    case ADD_USER_SPINNER_EN:
      return {
        ...state,
        add_user_spinner: true,
      };
    case ADD_USER_SPINNER_DIS:
      return {
        ...state,
        add_user_spinner: false,
      };
    case REGISTER_SPINNER_EN:
      return {
        ...state,
        register_spinner: true,
      };
    case REGISTER_SPINNER_DIS:
      return {
        ...state,
        register_spinner: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      };
    case LOGIN_SPINNER_EN:
      return {
        ...state,
        login_spinner: true,
      };
    case LOGIN_SPINNER_DIS:
      return {
        ...state,
        login_spinner: false,
      };
    default:
      return state;
  }
}
