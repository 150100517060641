import React from "react";
import { Button } from "../Button/Buttons";
import { useNavigate } from "react-router-dom";

const LastSection = () => {
  const navigate = useNavigate();
  return (
    <section class="content-panel-brands no-min-height">
      <div class="content flush-left w-container">
        <div class="panel-text">
          <h2 style={{ textAlign: "center" }} class="panel-heading center">
            Gérez Mieux Vos Boutiques En Ligne
          </h2>
          <p class="panel-paragraph center">
            Automatisez le suivi de vos inventaires avec un tableau de bord
            puissant et intuitif. Simplifiez la gestion de vos produits et
            arrivages pour une efficacité maximale.
          </p>
        </div>
      </div>
      <div class="page-padding s0">
        <Button
          onClick={() => navigate("/register")}
          style={{
            borderRadius: 30,
            marginTop: 10,
            fontSize: 15,
            padding: "23px",
          }}
          size="default"
          type="primary"
        >
          Essai Gratuit
        </Button>
      </div>
    </section>
  );
};

export default LastSection;
