import {
  GET_STATS_SPINNER_DIS,
  GET_STATS_SPINNER_EN,
  GET_STATS_SUCCESS,
} from "../types";

const initialState = {
  cmdStats: {},
  clsStats: {},
  get_stats_spinner: false,
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STATS_SPINNER_EN:
      return {
        ...state,
        get_stats_spinner: true,
      };
    case GET_STATS_SPINNER_DIS:
      return {
        ...state,
        get_stats_spinner: false,
      };
    case GET_STATS_SUCCESS:
      return {
        ...state,
        cmdStats: action.cmdPayload,
        clsStats: action.coliPayload
      };
    default:
      return state;
  }
}
