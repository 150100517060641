import {
  ADD_FOURNISSEUR_SPINNER_DIS,
  ADD_FOURNISSEUR_SPINNER_EN,
  ADD_FOURNISSEUR_SUCCESS,
  ADD_SWITCH_ID_TO_LOADER,
  DELETE_FOURNISSEUR_SPINNER_DIS,
  DELETE_FOURNISSEUR_SPINNER_EN,
  DELETE_FOURNISSEUR_SUCCESS,
  GET_FOURNISSEUR_SPINNER_DIS,
  GET_FOURNISSEUR_SPINNER_EN,
  GET_FOURNISSEUR_SUCCESS,
  REMOVE_SWITCH_ID_FROM_LOADER,
  RESTORE_FOURNISSEUR_SPINNER_DIS,
  RESTORE_FOURNISSEUR_SPINNER_EN,
  RESTORE_FOURNISSEUR_SUCCESS,
  UPDATE_FOURNISSEUR_SUCCESS,
} from "../types";

const initialState = {
  fournisseurs: [],
  add_spinner: false,
  get_spinner: false,
  switch_spinner: false,
  switch_fournIds: [],
};

export default function fournisseurReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_FOURNISSEUR_SUCCESS:
      const index = state.fournisseurs.findIndex(
        (f) => f.id?.toString() === action.id?.toString()
      );
      if (index !== -1) {
        const updatedFournisseurs = [...state.fournisseurs];
        updatedFournisseurs[index] = {
          ...updatedFournisseurs[index],
          deleted: true,
        };
        return {
          ...state,
          fournisseurs: updatedFournisseurs,
        };
      }
      return state;
    case RESTORE_FOURNISSEUR_SUCCESS:
      const idx = state.fournisseurs.findIndex(
        (f) => f.id?.toString() === action.id?.toString()
      );
      if (idx !== -1) {
        const updatedFournisseurs = [...state.fournisseurs];
        updatedFournisseurs[idx] = {
          ...updatedFournisseurs[idx],
          deleted: false,
        };
        return {
          ...state,
          fournisseurs: updatedFournisseurs,
        };
      }
      return state;
    case ADD_SWITCH_ID_TO_LOADER:
      if (!state.switch_fournIds.includes(action.id)) {
        return {
          ...state,
          switch_fournIds: [...state.switch_fournIds, action.id],
        };
      }
      return state;

    case REMOVE_SWITCH_ID_FROM_LOADER:
      return {
        ...state,
        switch_fournIds: state.switch_fournIds.filter((id) => id !== action.id),
      };

    case RESTORE_FOURNISSEUR_SPINNER_EN:
      return {
        ...state,
        switch_spinner: true,
      };
    case RESTORE_FOURNISSEUR_SPINNER_DIS:
      return {
        ...state,
        switch_spinner: false,
      };
    case DELETE_FOURNISSEUR_SPINNER_EN:
      return {
        ...state,
        switch_spinner: true,
      };
    case DELETE_FOURNISSEUR_SPINNER_DIS:
      return {
        ...state,
        switch_spinner: false,
      };
    case UPDATE_FOURNISSEUR_SUCCESS:
      const { fournisseur, fournisseurId } = action.payload;
      const updatedFournisseur = state.fournisseurs.map((f) =>
        f.id === fournisseurId ? fournisseur : f
      );
      return {
        ...state,
        fournisseurs: updatedFournisseur,
      };
    case GET_FOURNISSEUR_SPINNER_EN:
      return {
        ...state,
        get_spinner: true,
      };
    case GET_FOURNISSEUR_SPINNER_DIS:
      return {
        ...state,
        get_spinner: false,
      };
    case GET_FOURNISSEUR_SUCCESS:
      return {
        ...state,
        fournisseurs: action.payload,
      };
    case ADD_FOURNISSEUR_SPINNER_DIS:
      return {
        ...state,
        add_spinner: false,
      };
    case ADD_FOURNISSEUR_SPINNER_EN:
      return {
        ...state,
        add_spinner: true,
      };
    case ADD_FOURNISSEUR_SUCCESS:
      return {
        ...state,
        fournisseurs: [...state.fournisseurs, action.payload],
      };
    default:
      return state;
  }
}
