import {
  GET_USER_SPINNER_DIS,
  GET_USER_SPINNER_EN,
  GET_USER_SUCCESS,
  UPDATE_PERMISSION_ON_USER_DETAILS,
} from "../types";

const initialState = {
  userDetails: null,
  getSpecUserLoader: false,
};

export default function udataReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PERMISSION_ON_USER_DETAILS:
      return {
        ...state,
        userDetails: {
        ...state.userDetails,
        permissions: action.payload,
        },
      };
    case GET_USER_SPINNER_EN:
      return {
        ...state,
        getSpecUserLoader: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case GET_USER_SPINNER_DIS:
      return {
        ...state,
        getSpecUserLoader: false,
      };
    default:
      return state;
  }
}
