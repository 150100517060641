// import { Navbar, Nav, Button, Container } from "react-bootstrap";
import LogoOrtyAcceuil from "../../assets/img/logo-orty-acceuil.png";
import { Button } from "../Button/Buttons";
import MobileNavbar from "./MobileNavbar";
import { useNavigate } from "react-router-dom";
// Assuming you have Bootstrap CSS included in your project

const CustomNavbar = () => {
  const navigate = useNavigate();
  return (
    // <Navbar
    //   className="bg-body-tertiary navbar-shadow"
    //   bg="primary"
    //   fixed="top"
    //   style={{
    //     padding: "4px 10px",
    //   }}
    // >
    //   <Container fluid className="navbar-container-px">
    //     <Navbar.Brand>
    //       <img
    //         src={LogoOrtyAcceuil}
    //         width="70"
    //         alt="logo orty"
    //       />
    //     </Navbar.Brand>
    //     <Navbar.Toggle />
    //     <Navbar.Collapse className="justify-content-end">
    //       <Nav className="ml-auto">
    //         <Nav.Link href="/login">
    //           <div className="navbt btn-outline">
    //             <span>Se connecter</span>
    //           </div>
    //         </Nav.Link>
    //         <Nav.Link href="/register">
    //           <div className="navb-connect orty-btn-orange">
    //             <span>Essai gratuit</span>
    //           </div>
    //         </Nav.Link>
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
    <>
      <MobileNavbar />
      <div class="top-navigation-wrapper">
        <div class="navbar-content">
          <a
            href="/"
            aria-current="page"
            class="home-link-block w-inline-block w--current"
          >
            <img
              src={LogoOrtyAcceuil}
              // src="https://cdn.prod.website-files.com/6475c5eea8e20ed4cd6ee36e/647d9d0bc78e346059974009_Logo%402x.webp"
              loading="lazy"
              alt=""
              class="image-2"
            />
          </a>
          <div class="menu-cta">
            <span
              onClick={() => {
                navigate("/login");
              }}
              style={{ cursor: "pointer" }}
              // href="https://app.latana.com/login"
              class="link-block w-inline-block"
            >
              <div class="section-item-wrapper">
                <div class="section-text">Log in</div>
              </div>
            </span>
            <Button
              onClick={() => {
                navigate("/register");
              }}
              style={{
                borderRadius: 30,
                // marginTop: 10,
                fontSize: 15,
                padding: "23px",
              }}
              size="default"
              type="primary"
            >
              Essai Gratuit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomNavbar;
