import {
  ADD_BOUTIQUE_SPINNER_DIS,
  ADD_BOUTIQUE_SPINNER_EN,
  ADD_BOUTIQUE_SUCCESS,
  GET_BOUTIQUES_SPINNER_DIS,
  GET_BOUTIQUES_SPINNER_EN,
  GET_BOUTIQUES_SUCCESS,
} from "../types";

const initialState = {
  boutiques: null,
  get_boutiques_loader: false,
  // boutiques: [
  //   {
  //     id: 101,
  //     name: "Elegant Trends",
  //     email: "info@eleganttrends.com",
  //     logo: null,
  //     phone: "+1 (555) 123-4567",
  //     fiscale_code: "E123456789",
  //     boutique_link: "www.eleganttrends.com",
  //     description:
  //       "A boutique offering a curated selection of high-end fashion for those who appreciate timeless elegance.",
  //   },
  //   {
  //     id: 102,
  //     name: "Urban Chic Boutique",
  //     email: "contact@urbanchicboutique.com",
  //     logo: null,
  //     phone: "+1 (555) 987-6543",
  //     fiscale_code: "U987654321",
  //     boutique_link: "www.urbanchicboutique.com",
  //     description:
  //       "A trendy boutique catering to the urban fashionista, featuring the latest styles and streetwear.",
  //   },
  //   {
  //     id: 103,
  //     name: "Vintage Vogue Emporium",
  //     email: "vintagevogue@email.com",
  //     logo: null,
  //     phone: "+1 (555) 321-7890",
  //     fiscale_code: "V345678901",
  //     boutique_link: "www.vintagevogueemporium.com",
  //     description:
  //       "Explore the charm of bygone eras at Vintage Vogue Emporium, where classic and retro fashion come to life.",
  //   },
  //   {
  //     id: 104,
  //     name: "Boho Bliss Boutique",
  //     email: "bohobliss@boutiquemail.com",
  //     logo: null,
  //     phone: "+1 (555) 456-7890",
  //     fiscale_code: "B234567890",
  //     boutique_link: "www.bohoblissboutique.com",
  //     description:
  //       "Embrace the free-spirited style at Boho Bliss Boutique, your destination for bohemian-inspired clothing and accessories.",
  //   },
  // ],
  add_boutique_loader: false,
};

export default function boutiqueReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BOUTIQUES_SPINNER_EN:
      return {
        ...state,
        get_boutiques_loader: true,
      };
    case GET_BOUTIQUES_SPINNER_DIS:
      return {
        ...state,
        get_boutiques_loader: false,
      };
    case GET_BOUTIQUES_SUCCESS:
      return {
        ...state,
        boutiques: [...action.payload],
      };
    case ADD_BOUTIQUE_SPINNER_EN:
      return {
        ...state,
        add_boutique_loader: true,
      };
    case ADD_BOUTIQUE_SPINNER_DIS:
      return {
        ...state,
        add_boutique_loader: false,
      };
    case ADD_BOUTIQUE_SUCCESS:
      return {
        ...state,
        boutiques: [action.payload, ...state.boutiques],
      };
    default:
      return state;
  }
}
