import {
  ADD_PAIEMENT_LOADER_DIS,
  ADD_PAIEMENT_LOADER_EN,
  ADD_PAIEMENT_SUCCESS,
  GET_PAIEMENTS_LOADER_DIS,
  GET_PAIEMENTS_LOADER_EN,
  GET_PAIEMENTS_SUCCESS,
  UPDATE_PAIEMENT_SUCCESS,
} from "../types";

const initialState = {
  paiementsRecu: [],
  paiementEffectued: [],
  add_paiement_spinner: false,
  get_paiement_spinner: false,
};

export default function paiementReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PAIEMENT_SUCCESS:
      const { uPaiement, paiementId, uType } = action.payload;
      if (uType === "Réçu") {
        const updatedPaiement = state.paiementsRecu.map((p) =>
          p.id === paiementId ? uPaiement : p
        );
        return {
          ...state,
          paiementsRecu: updatedPaiement,
        };
      } else {
        const updatedPaiement = state.paiementEffectued.map((p) =>
          p.id === paiementId ? uPaiement : p
        );
        return {
          ...state,
          paiementEffectued: updatedPaiement,
        };
      }

    case GET_PAIEMENTS_LOADER_EN:
      return {
        ...state,
        get_paiement_spinner: true,
      };
    case GET_PAIEMENTS_LOADER_DIS:
      return {
        ...state,
        get_paiement_spinner: false,
      };
    case GET_PAIEMENTS_SUCCESS:
      var { type, paiements } = action.payload;
      if (type === "Réçu") {
        return {
          ...state,
          paiementsRecu: paiements,
        };
      } else {
        return {
          ...state,
          paiementEffectued: paiements,
        };
      }
    case ADD_PAIEMENT_LOADER_EN:
      return {
        ...state,
        add_paiement_spinner: true,
      };
    case ADD_PAIEMENT_LOADER_DIS:
      return {
        ...state,
        add_paiement_spinner: false,
      };
    case ADD_PAIEMENT_SUCCESS:
      var { type, paiement } = action.payload;
      if (type === "Réçu") {
        return {
          ...state,
          paiementsRecu: [...state.paiementsRecu, paiement],
        };
      } else {
        return {
          ...state,
          paiementEffectued: [...state.paiementEffectued, paiement],
        };
      }
    default:
      return state;
  }
}
